// TODO handle non-200 reponses before parsing the json
import history from './history'

export default class Api {
  buildHeaders = (access_token) => {
    var headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'joy-app-name': 'patient_app',
      'joy-platform': 'web',
      'joy-user-agent': global.navigator.userAgent
    }
    if (access_token) {
      headers['Access-Token'] = access_token
    }
    return headers
  }

  GET = (access_token, path) => {
    return new Promise((resolve, reject) => {
      const headers = this.buildHeaders(access_token)
      fetch(path, {
        method: 'GET',
        headers
      }).then((response) => {
        const content_type = response.headers.get("content-type");
        if (content_type && content_type.indexOf("application/json") !== -1) {
          return response.json()
        } else {
          if (response.status >= 200 && response.status < 300) {
            resolve(response.status)
          } else {
            reject(response.status)
          }
        }
      }).then((json) => {
        if (json.joy_error && json.joy_error === 'token_expired') {
          history.push('/logout')
        } else {
          resolve(json)
        }
      }).catch((err) => {
        reject(err)
      })
    });
  }

  POST = (access_token, path, body) => {
    return new Promise((resolve, reject) => {
      const headers = this.buildHeaders(access_token)
      fetch(path, {
        method: 'POST',
        headers,
        body: body
      }).then((response) => {
        const content_type = response.headers.get("content-type");
        if (content_type && content_type.indexOf("application/json") !== -1) {
          return response.json()
        } else {
          if (response.status >= 200 && response.status < 300) {
            resolve(response.status)
          } else {
            reject(response.status)
          }
        }
      }).then((json) => {
        if (json && json.joy_error && json.joy_error === 'token_expired') {
          history.push('/logout')
        } else {
          resolve(json)
        }
      }).catch((err) => {
        reject(err)
      })
    });
  }

  PUT = (access_token, path, body) => {
    return new Promise((resolve, reject) => {
      const headers = this.buildHeaders(access_token)
      fetch(path, {
        method: 'PUT',
        headers,
        body: body
      }).then((response) => {
        const content_type = response.headers.get("content-type");
        if (content_type && content_type.indexOf("application/json") !== -1) {
          return response.json()
        } else {
          if (response.status >= 200 && response.status < 300) {
            resolve(response.status)
          } else {
            reject(response.status)
          }
        }
      }).then((json) => {
        if (json && json.joy_error && json.joy_error === 'token_expired') {
          history.push('/logout')
        } else {
          resolve(json)
        }
      }).catch((err) => {
        reject(err)
      })
    });
  }

  DELETE = (access_token, path, body) => {
    return new Promise((resolve, reject) => {
      const headers = this.buildHeaders(access_token)
      fetch(path, {
        method: 'DELETE',
        headers,
        body: body
      })
        .then(response => {
          const content_type = response.headers.get('content-type')
          if (content_type && content_type.indexOf('application/json') !== -1) {
            return response.json()
          } else {
            if (response.status >= 200 && response.status < 300) {
              resolve(response.status)
            } else {
              reject(response.status)
            }
          }
        })
        .then(json => {
          if (json && json.joy_error && json.joy_error === 'token_expired') {
            history.push('/logout')
          } else {
            resolve(json)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
