import React, { Component } from 'react';

export default class Android extends Component {
  render() {
    return (
      <svg width="27px" height="31px" viewBox="0 0 27 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Signed-up,-try-to-get-to-download-mobile-app...-Copy-4" transform="translate(-790.000000, -575.000000)" fillRule="nonzero">
                  <g id="Android_robot" transform="translate(791.000000, 576.000000)">
                      <g id="Group" fill="#FFFFFF">
                          <g id="b-link" transform="translate(4.545455, 2.220114)" stroke="#FFFFFF" strokeWidth="1.25874126">
                              <g id="b">
                                  <ellipse id="Oval" cx="7.95454545" cy="7.17267552" rx="7.95454545" ry="7.17267552"></ellipse>
                                  <rect id="Rectangle-path" x="0" y="5.37950664" width="15.9090909" height="15.540797" rx="1.92307692"></rect>
                              </g>
                          </g>
                          <g id="a-link" transform="translate(15.500000, 15.000000) scale(-1, 1) translate(-15.500000, -15.000000) translate(6.000000, 0.000000)" stroke="#FFFFFF" strokeWidth="0.629370629">
                              <g id="a">
                                  <rect id="Rectangle-path" transform="translate(15.889556, 3.529123) rotate(29.000000) translate(-15.889556, -3.529123) " x="15.3213739" y="-0.142603906" width="1.13636364" height="7.34345351" rx="0.568181818"></rect>
                                  <rect id="c" x="0" y="9.39278937" width="4.1958042" height="11.3567362" rx="2.0979021"></rect>
                                  <g id="c-link" transform="translate(7.430070, 17.675522)">
                                      <rect id="c" x="0" y="0" width="4.1958042" height="11.3567362" rx="2.0979021"></rect>
                                  </g>
                              </g>
                          </g>
                          <g id="a" stroke="#FFFFFF" strokeWidth="0.629370629">
                              <rect id="Rectangle-path" transform="translate(15.889556, 3.529123) rotate(29.000000) translate(-15.889556, -3.529123) " x="15.3213739" y="-0.142603906" width="1.13636364" height="7.34345351" rx="0.568181818"></rect>
                              <rect id="c" x="0" y="9.39278937" width="4.1958042" height="11.3567362" rx="2.0979021"></rect>
                              <g id="c-link" transform="translate(7.430070, 17.675522)">
                                  <rect id="c" x="0" y="0" width="4.1958042" height="11.3567362" rx="2.0979021"></rect>
                              </g>
                          </g>
                          <g id="b" transform="translate(4.545455, 2.220114)">
                              <ellipse id="Oval" cx="7.95454545" cy="7.17267552" rx="7.95454545" ry="7.17267552"></ellipse>
                              <rect id="Rectangle-path" x="0" y="5.37950664" width="15.9090909" height="15.540797" rx="1.92307692"></rect>
                          </g>
                      </g>
                      <g id="Group" transform="translate(4.000000, 5.806452)" fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.629370629">
                          <path d="M0,4.14136622 L16.6083916,4.14136622" id="Shape"></path>
                          <ellipse id="Oval" cx="4.63286713" cy="0.341555977" rx="1" ry="1"></ellipse>
                          <ellipse id="Oval" cx="11.9755245" cy="0.341555977" rx="1" ry="1"></ellipse>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    )
  }
}
