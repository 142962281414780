import React from 'react'
import styles from './invalidurl.module.css'
import { isMobileOnly } from 'react-device-detect'

export default function InvalidURL() {
  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.inner}>
          <div
            className={isMobileOnly ? styles.mobile_content : styles.content}
          >
            <h1
              style={{
                marginTop: '250px',
                textAlign: 'center',
                fontSize: '32px',
                fontWeight: 'bold'
              }}
            >
              Hmmmm, are you sure you have the right URL?
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
