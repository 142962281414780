import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { theme as defaultJoyTheme } from '@bit/blueprint-health.joy.library/dist/theme'

import './lib/yup-phone'

import './index.css'
import Routes from './routes'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import flagsmith from "flagsmith"


const theme = createTheme({
  palette: {
    primary: {
      light: '#7485ff',
      main: '#2d54e8',
      dark: '#3948b2',
      contrastText: '#fff'
    },
    secondary: {
      main: '#2d54e8'
    }
  }
})

if (process.env.REACT_APP_ENV !== 'development') {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_CODE)
  ReactGA.pageview(window.location.pathname + window.location.search)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY
  })
  Sentry.configureScope(scope => {
    scope.addEventProcessor(async event => {
      return event
    })
  })
}

class App extends Component {
  constructor(props){
    super(props)
    this.state={
      featureFlagsLoaded: false
    }
    flagsmith.init({
      environmentID: process.env.REACT_APP_BULLET_TRAIN_ENV_ID,
      onChange: () => { // Occurs whenever flags are changed
        this.setState({ featureFlagsLoaded: true})
      },
      onError: error => {
        if (error.detail) Sentry.captureException(`Flagsmith Error: ${error.detail}`)
      }
    })
  }
  render(){
    return (
      <ChakraProvider theme={defaultJoyTheme}>
        <MuiThemeProvider theme={theme}>
          <Routes />
        </MuiThemeProvider>
      </ChakraProvider>
    )
  }
}



ReactDOM.render(<App />, document.getElementById('root'))
