import React, { Component } from 'react';

export default class Logo extends Component {
  render() {
    const { color, variant } = this.props
    if (color === 'white') {
      if (variant === 'pdf') {
        return (
          <img className={this.props.className} style={{maxWidth: '200px'}} src="https://res.cloudinary.com/hellojoy/image/upload/v1553776897/White_XL_2x_hgf8de.png" alt="blueprint logo" />
        )
      } else {
        return (
          <img className={this.props.className} style={{maxWidth: '343px'}} src="https://res.cloudinary.com/hellojoy/image/upload/v1553776897/White_XL_2x_hgf8de.png" alt="blueprint logo" />
        )
      }
    }
    else {
      return (
        <img className={this.props.className} style={{maxWidth: '343px'}} src="https://res.cloudinary.com/hellojoy/image/upload/v1553000305/blueprint_fsmkn7.png" alt="blueprint logo" />
      )
    }
  }
}
