import React, { Component } from 'react'
import Api from '../api'
import { PrimaryButton, PasswordRules } from '../components'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import queryString from 'query-string'
import history from '../history'
import { CircleCheck } from '../components/icons'
import _ from 'lodash'
import * as Copy from '../copy';

const api = new Api()

const styles = theme => {
  let styles = {
    page: {
      width: '100%',
      height: '100%',
      display: 'flex',
      marginTop: '-66px',
    },
    container: {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto auto',
      maxWidth: 500,
      padding: 40,
      boxSizing: 'border-box',
    },
    textfield: {
      margin: '10px 0',
      width: '100%',
    },
    form: {
      boxSizing: 'border-box',
      marginBottom: 15,
      padding: 0,
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexFlow: 'column',
    },
    header: {
      marginBottom: 10,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 24
    },
    successDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      height: 150,
    },
    successCheckContainer: {
      height: 60,
    },
    successCheck: {
      width: 40,
      height: '100%',
    },
    error: {
      color: '#e60000',
      fontSize: 13,
      marginBottom: 10,
    },
    '@media only screen and (max-width: 414px)': {
      container: {
        padding: 20,
        height: '100vh',
        justifyContent: 'flex-start',
        paddingTop: 80,
      },
    },
  }


  if (theme.styles && theme.styles.forgotPassword) {
    styles = _.merge(styles, theme.styles.forgotPassword)
  }

  return styles
}


const PASSWORD_RULES = [
  {
    description: 'No spaces',
    regex: /^\S*$/,
    isValid: false
  },
  {
    description: 'At least one number',
    regex: /.*[0-9]+.*/,
    isValid: false
  },
  {
    description: 'Uppercase and lowercase',
    regex: /(?=.*[a-z])(?=.*[A-Z])/,
    isValid: false
  },
  {
    description: 'At least 8 characters',
    regex: /^.{8,}$/,
    isValid: false
  },
  {
    description: 'At least one special character',
    regex: /[.,!&^%$#@()/_*\]<>{}"~`|'?[\\;:-]/, //TODO match with amazon regex.
    isValid: false
  }
]

class ForgotPasswordCode extends Component {
  state = {
    passwordReset: false
  }

  constructor(props) {
    super(props)
    this.state = {
      code: '',
      password: '',
      password_confirm: ''
    }

    this.email = queryString.parse(props.location.search).email
  }

  componentDidMount = () => {
    if (!this.email) {
      history.push('/forgot-password')
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  showPasswordRules = name => {
    if (name === 'password') {
      this.setState({
        showPasswordRules: true
      })
    }
  }

  handleFocus = evt => {
    this.showPasswordRules(evt.target.name)
  }

  validatePassword = () => {
    let isPasswordValid = true
    for (var i = 0; i < PASSWORD_RULES.length; i++) {
      const isRuleValid = this.state.password.match(PASSWORD_RULES[i].regex)
      if (!isRuleValid) {
        isPasswordValid = false
        break
      }
    }
    return isPasswordValid
  }

  confirmPasswords = () => {
    return this.state.password === this.state.password_confirm
  }

  handleSubmit = evt => {
    evt.preventDefault()
    this.setState(
      {
        loading: true,
        error: null
      },
      () => {
        const isPasswordValid = this.validatePassword()
        const isPasswordSame = this.confirmPasswords()
        if (
          isPasswordValid &&
          isPasswordSame &&
          this.email &&
          this.state.code
        ) {

          const { code, password } = this.state
          const path =
            process.env.REACT_APP_NODE_API_ROOT_URL + '/forgot-password/confirm'
          const email = this.email.replace(/ /g, '+')

          api
            .POST(
              null,
              path,
              JSON.stringify({
                user_type: 'patient',
                code: code.trim(),
                email,
                new_password: password
              })
            )
            .then(response => {
              if (response.error) {
                throw new Error(response.error)
              }

              this.setState(
                {
                  loading: false,
                  success: true
                },
                () => {
                  this.setState({ passwordReset: true })
                }
              )
            })
            .catch(() => {
              this.setState({
                loading: false,
                error: 'Something went wrong. Please try again.'
              })
            })
        } else {
          if (!isPasswordValid) {
            this.setState({
              loading: false,
              error: 'Please make sure your password meets our requirements'
            })
          } else if (!isPasswordSame) {
            this.setState({
              loading: false,
              error: 'Please make sure you entered the same password twice'
            })
          } else if (!this.state.code) {
            this.setState({
              loading: false,
              error: 'Please enter your reset code from the email we sent you'
            })
          } else {
            this.setState({
              loading: false,
              error: 'Something went wrong. Please try again.'
            })
          }
        }
      }
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.page}>
        <Paper elevation={0} className={classes.container}>
          {this.state.passwordReset ? (
            <div className={classes.successDiv}>
              <div className={classes.successCheckContainer}>
                <CircleCheck className={classes.successCheck} fill="#44CD7D" />
              </div>
              <h1 className={classes.header}>
                Password successfully reset! Please return to the login screen
                to sign in.
              </h1>
            </div>
          ) : (
            <div>
              <h1 className={classes.header}>Reset your password</h1>
              <p>
                {Copy.forgotPasswordResetCode()}
              </p>
              <form onSubmit={this.handleSubmit} className={classes.form}>
                <div className={classes.textfield}>
                  <TextField
                    id="Reset Code"
                    autoFocus
                    margin="dense"
                    label="Reset code from your email"
                    fullWidth
                    required
                    onChange={this.handleChange('code')}
                    value={this.state.code}
                    variant="outlined"
                  />
                  <TextField
                    id="New password"
                    name="password"
                    onFocus={this.handleFocus}
                    margin="dense"
                    label="Password"
                    type="password"
                    fullWidth
                    required
                    onChange={this.handleChange('password')}
                    value={this.state.password}
                    variant="outlined"
                  />
                  {this.state.showPasswordRules && (
                    <PasswordRules
                      rules={PASSWORD_RULES}
                      password={this.state.password}
                    />
                  )}
                  <TextField
                    id="Confirm new password"
                    margin="dense"
                    label="Confirm password"
                    type="password"
                    fullWidth
                    required
                    onChange={this.handleChange('password_confirm')}
                    value={this.state.password_confirm}
                    variant="outlined"
                  />
                </div>
                {this.state.error && (
                  <p className={classes.error}>{this.state.error}</p>
                )}
                <PrimaryButton
                  loading={this.state.loading}
                  success={this.state.success}
                  type="submit"
                  fullWidth
                >
                  Reset password
                </PrimaryButton>
              </form>
              {Copy.forgotPasswordSupport('patient')} {/* TODO: user_type here should be dynamic*/}
            </div>
          )}
        </Paper>
      </div>
    )
  }
}
const FPC = withStyles(styles)(ForgotPasswordCode)
export { FPC as ForgotPasswordCode }

class ForgotPassword extends Component {
  state = {
    open: false,
    email: '',
    loading: false
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  triggerEmailReset = e => {
    e.preventDefault()
    this.setState(
      {
        loading: true,
        error: null
      },
      () => {

        const { email } = this.state
        const path =
          process.env.REACT_APP_NODE_API_ROOT_URL +
          '/forgot-password/request-code'

        api
          .POST(null, path, JSON.stringify({ user_type: 'patient', email }))
          .then(response => {
            if (response.error) {
              throw new Error(response.error)
            }

            const queryParams = new URLSearchParams(this.props.location.search)
            let themeQueryParam =
              queryParams.get('theme') || 'main'

            themeQueryParam = queryString.stringify({ theme: themeQueryParam })

            const path = `/forgot-password-code?email=${email}&${themeQueryParam}`
            this.setState(
              {
                loading: false,
                success: true
              },
              () => {
                setTimeout(() => {
                  history.push(path)
                }, 1000)
              }
            )
          })
          .catch(() => {
            this.setState({
              loading: false,
              error: 'Something went wrong. Please try again.'
            })
          })
      }
    )
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.page}>
        <Paper elevation={0} className={classes.container}>
          <p>Enter your email address below to reset your password</p>
          <form onSubmit={this.triggerEmailReset} className={classes.form}>
            <div className={classes.textfield}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email"
                type="email"
                fullWidth
                required
                onChange={this.handleChange('email')}
                value={this.state.email}
                variant="outlined"
              />
            </div>
            {this.state.error && (
              <p className={classes.error}>{this.state.error}</p>
            )}
            <PrimaryButton
              loading={this.state.loading}
              success={this.state.success}
              type="submit"
              fullWidth
            >
              Reset password
            </PrimaryButton>
          </form>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(ForgotPassword)