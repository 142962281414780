import React from 'react'
import styles from './intakesuccess.module.css'
import { CircleCheck } from '../components/icons'
import * as Copy from '../copy'

export default function IntakeSuccess () {

  const renderQuestion = (title, body) => {
    return (
      <div className={styles.question}>
        <p className={styles.questiontitle}>{title}</p>
        <p className={styles.questionbody}>{body}</p>
      </div>
    )
  }
  return (
    <div className={styles.final_container}>
      <div className={styles.successContainer}>
        <div className={styles.successCheckContainer}>
          <CircleCheck className={styles.successCheck} fill="#44CD7D" />
        </div>
        <p className={styles.successtitle}>{Copy.intakeSuccessTitle()}</p>
        <p className={styles.successtext}>{Copy.intakeSuccessBody()}</p>
        <div className={styles.successquestions}>
          {renderQuestion(Copy.intakeSuccessClinicQuestionsTitle(), Copy.intakeSuccessClinicQuestions())}
          {renderQuestion(Copy.intakeSuccessTechQuestionsTitle(), Copy.intakeSuccessTechQuestions())}
        </div>
      </div>
    </div>
  )
}