import React from 'react'
import Card from '@material-ui/core/Card'
import styles from './safetynet.module.css'
import CallIcon from '../../images/Call.png'
import SMSIcon from '../../images/SMS.png'
import LinkIcon from '../../images/Link.png'



export default function SafetyNet (props) {
  const { title, subtitle, disclaimer, resources } = props.prompt

  const renderResources = () => {
    return resources.map((resource) => <ResourceCard key={resource.id} resource={resource} />)
  }

  return(
    <div className={styles.container}>
      <div className={styles.safetyNetContainer}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{subtitle}</p>
        <div className={styles.resourcePanel}>{renderResources()}</div>
        <p className={styles.disclaimer}>{disclaimer}</p>
        {props.navigateToFinalFromSafety && (
          <p
            className={styles.skip}
            onClick={props.navigateToFinalFromSafety}
            tabIndex="0"
            role='button'
            onKeyDown={e => {
              const key = e.which
              if (key === 13 || key === 32) props.navigateToFinalFromSafety()
            }}
          >
            Skip
          </p>
        )}
      </div>
    </div>
  )
}

const ResourceCard = props => {
  const { display_text, type, action } = props.resource
  const handleResourceType = (type, action) => {
    switch (type) {
      case 'call':
        return {
          href: `tel:${action.phone}`,
          image: <img alt="" src={CallIcon} />,
          displayDescription: <><span>{'Call: '}</span><span className={styles.underline}>{action.phone}</span></>
        }
      case 'sms':
        return {
          href: `sms:${action.phone}&body=HOME`,
          image: <img alt="" src={SMSIcon} />,
          displayDescription: <><span>{'Text: '}</span><span className={styles.underline}>{action.phone}</span></>
        }
      case 'web':
        return {
          href: action.url,
          image: <img alt="" src={LinkIcon}/>,
          displayDescription: <><span>{'Web: '}</span><span className={styles.underline}>{action.url}</span></>
        }
      default:
        return {}
    }
  }

  const { href, image, displayDescription } = handleResourceType(type, action)

  return (
    <a href={href}>
      <Card
        elevation={3}
        className={styles.resourceCard}
      >
        <div className={styles.resourceIcon}>
          {image}
        </div>
        <div>
          <p className={styles.resourceDisplayText}>
            {display_text}
          </p>
          <p>{displayDescription}</p>
        </div>
      </Card>
    </a>
  )
}
