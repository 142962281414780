import React from 'react'
import styles from './consent.module.css'
import { PrimaryButton } from '../../components'
import { ASSESSMENT_SOURCE } from '../../constants'

export default function Consent(props) {

  // returns copy seperated by line breaks
  const processCopy = () => {
    const paragraphs = props.copy.split("\\n")
    return paragraphs.map(p => {
      return (
        <div key={p}>
          <p>{p}</p>
          <br/>
        </div>
      )
    })
  }

  return (
    <div className={styles.container}>
      <span className={styles.title}>Consent to Using Blueprint Platform</span>
      <div className={styles.main}>{processCopy()}</div>
      <span className={styles.error}>{props.error}</span>
      <PrimaryButton
        className={styles.accept}
        onClick={() => props.giveConsent()}
      >I agree</PrimaryButton>
      <p
        className={styles.signout}
        onClick={props.navigateToHomeScreen}
        tabIndex="0"
        role='button'
        onKeyDown={e => {
          const key = e.which
          if (key === 13 || key === 32) props.navigateToHomeScreen()
        }}
      >
      {props.source && props.source.toLowerCase() === ASSESSMENT_SOURCE.KIOSK &&
          'or sign out'
      }
      
      </p>
    </div>
    
  ) 
}