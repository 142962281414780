import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core'
import InputMask from 'react-input-mask'

export default function BPDatePicker(props) {
  const {
    value,
    onChange,
    placeholder,
    id,
    required,
    styles,
    dense,
    ...rest
  } = props

  const mergedStyles = () => ({
    ...styles,
    button: {
      display: 'flex',
      flexDirection: 'row',
      border: 'solid',
      borderColor: '#c4c4c4',
      borderWidth: '1px',
      borderRadius: '3px',
      '&>div': {
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '2px 3px 1px 3px',
        cursor: 'pointer',
        width: '100%'
      }
    }
  })
  const useStyles = makeStyles(mergedStyles)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <InputMask mask={'99/99/9999'} onChange={onChange} value={value}>
        {() => (
          <TextField
            id={id}
            label={placeholder}
            margin={dense && 'dense'}
            variant="outlined"
            required={required}
            className={classes.input}
            {...rest}
          />
        )}
      </InputMask>
    </div>
  )
}
