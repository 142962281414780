import React from 'react';

const CircleCheck = (props) => (
    <svg className={props.className} width="16px" height="16px" viewBox="0 0 16 16" version="1.1" aria-hidden="true" >
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-677.000000, -1258.000000)" fill={props.fill ? props.fill : "#FFFFFF"}>
                <g transform="translate(529.000000, 1243.000000)">
                    <path d="M159.025778,20.2055261 L154.631704,24.6056891 L152.996148,22.970073 C152.785778,22.7673988 152.345481,22.6909515 152.067556,22.9748139 C151.792,23.257491 151.835852,23.712619 152.04563,23.9141079 L154.164741,26.0380384 C154.270222,26.1393755 154.502519,26.2673803 154.640593,26.2673803 C154.778667,26.2673803 154.981333,26.157154 155.086815,26.0558169 L159.989333,21.1560799 C160.199111,20.9539983 160.245333,20.4858328 159.965037,20.210267 C159.680593,19.9299604 159.236148,20.0034446 159.025778,20.2055261 M156,15 C160.41837,15 164,18.5817623 164,23.0002963 C164,27.4188303 160.41837,31 156,31 C151.581037,31 148,27.4188303 148,23.0002963 C148,18.5817623 151.581037,15 156,15 Z"></path>
                </g>
            </g>
        </g>
    </svg>

)
export default CircleCheck
