import React, { Component } from 'react'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import styles from './enroll.module.css'
import { isEmpty } from 'lodash'
import { Checkbox } from '@material-ui/core'

export default class EnrollAssessment extends Component {
  constructor (props) {
    super(props)
    this.firstAnswer = React.createRef()
  }

  componentDidMount () {
    this.firstAnswer.current.focus() // for screen readers
  }

  handleResponseChange = (event, key, isMultiSelect, isFreeText) => {
    this.props.handleStateChange(
      this.props.assessment.id,
      key, 
      event.target.value,
      isMultiSelect,
      isFreeText
    )
  }

  renderSections = assessment => {
    const { content } = assessment
    const responses = this.props.assessmentResponses[assessment.id]

    return content.sections.map((section, index) => {
      const { title, questions } = section
      const questionsNode = questions.map((question, questionIdx) => {
        return (
          <div className={styles.question} key={question.key}>
            <p className={styles.question_title} id={question.key}>{question.title}{question.skippable ? " You may optionally skip this question." : null}</p>
            {this.renderQuestion(question, responses, section, questionIdx)}
          </div>
        )
      })
      return (
        <div
          className={styles.section}
          id="question"
          data={assessment.id}
          key={index}
        >
          <p className={styles.section_header}>{title}</p>
          <div className={styles.questions}>{questionsNode}</div>
        </div>
      )
    })
  }

  renderQuestion = (question, responses, section, questionIdx) => {
    const isMultiSelect = question.type === "multi-select"
    const isFreeText = question.type === 'free_text'
    const FormController = (isMultiSelect || isFreeText) ? FormGroup : RadioGroup // nifty stuff
    return (
      <FormController
        aria-label={question.title}
        name={question.key}
        className={styles.answers}
        value={responses[question.key]}
        onChange={e => this.handleResponseChange(e, question.key, isMultiSelect, isFreeText)}
      >
        {this.renderAnswers(question, section, isMultiSelect, isFreeText, questionIdx)}
      </FormController>
    )
  }

  renderAnswers = (question, section, isMultiSelect, isFreeText, questionIdx) => {
    let answers = section.answers
    if (question.answers) {
      answers = question.answers
    }

    if (isFreeText) {
      return (
        <TextField multiline rows={4} />
      )
    }

    return answers.map((answer, index) => {
      const { value, title } = answer
      
      return (
        <FormControlLabel
          ref={questionIdx === 0 && index === 0 && this.firstAnswer}
          key={index}
          className={styles.label}
          value={value.toString()}
          label={title}
          control={this.renderControl(isMultiSelect, question.key, value)}
          disabled={this.props.submitted_assessments ? true : false}
        />
      )
    })
  }

  renderControl = (isMultiSelect, key, value) => {
    const { assessmentResponses: responses, assessment } = this.props
    const FormControl = isMultiSelect ? Checkbox : Radio
    const isChecked = isMultiSelect ? this.isCheckboxChecked : this.isRadioButtonChecked
    
    return <FormControl checked={isChecked(key, value, assessment, responses)} />
  }

  isRadioButtonChecked = (key, value, assessment, responses) => {
    return Boolean(
      responses[assessment.id] &&
      Number.isFinite(responses[assessment.id][key]) &&
      responses[assessment.id][key] === value
    )
  }

  isCheckboxChecked = (key, value, assessment, responses) => {
    return Boolean(
      responses[assessment.id] &&
      responses[assessment.id][key] &&
      responses[assessment.id][key].includes(value)
    )
  }

  renderAssessments = () => {
    const { assessment } = this.props
    if (!isEmpty(assessment)) {
      return (
        <form onSubmit={this.handleSubmit} autoComplete="off">
          {this.renderSections(assessment)}
        </form>
      )
    } else {
      return null
    }
  }

  render() {
    return this.renderAssessments()
  }
}
