import history from './history'
import {
  AuthenticationDetails,
  CookieStorage,
  CognitoUserPool,
  CognitoUser
} from 'amazon-cognito-identity-js'
import store from 'store'
import Api from './api'
const api = new Api()

const MINUTES_UNITL_AUTO_LOGOUT = 30 // in mins
const CHECK_INTERVAL = 1000 // in ms
const STORE_KEY = 'lastAction'

const POOL_DATA = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  Storage: new CookieStorage({
    domain: process.env.REACT_APP_DOMAIN,
    secure: true
  })
}

export default class Auth {
  constructor() {
    this.autoLogoutTimer = null
  }

  login = (email, password, firstTimeLoggingIn, cb) => {
    const authenticationData = {
      Username: email.toLowerCase(),
      Password: password
    }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    const userPool = new CognitoUserPool(POOL_DATA)
    const userData = {
      Username: email.toLowerCase(),
      Pool: userPool,
      Storage: new CookieStorage({
        domain: process.env.REACT_APP_DOMAIN,
        secure: true
      })
    }
    const cognitoUser = new CognitoUser(userData)

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        if (firstTimeLoggingIn) {
          history.replace('/download')
        } else {
          history.replace('/')
        }
        this.startAutoLogoutTimer(true)
      },
      onFailure: err => {
        if (cb) cb(err)
      }
    })
  }
  signup = (
    first_name,
    last_name,
    email,
    password,
    clinician_display_id,
    cb
  ) => {
    const body = JSON.stringify({
      first_name: first_name,
      last_name: last_name,
      email: email.toLowerCase(),
      password: password,
      clinician_display_id: clinician_display_id
    })
    const path = process.env.REACT_APP_NODE_API_ROOT_URL + '/auth/users/signup'
    api
      .POST(null, path, body)
      .then(() => {
        this.login(email.toLowerCase(), password, true)
      })
      .catch(err => {
        if (cb) cb(err)
      })
  }

  signupWithoutCredentials = (
    first_name,
    last_name,
    email,
    phone_number,
    display_id
  ) => {
    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      phone_number,
      display_id
    })
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL + '/patients/web-signup'
    return api.POST(null, path, body).catch(err => {
      console.error(err)
    })
  }

  logout = () => {
    const userPool = new CognitoUserPool(POOL_DATA)
    const cognitoUser = userPool.getCurrentUser()

    if (cognitoUser !== null) {
      cognitoUser.getSession(err => {
        if (err) {
          console.error(err.message || JSON.stringify(err))
          return
        }

        cognitoUser.globalSignOut({
          onSuccess: () => {
            this.clearAutoLogoutTimer()
            history.replace('/login')
          },
          onFailure: err => {
            console.error(err.message || JSON.stringify(err))
          }
        })
      })
    }
  }

  // checks if the user is authenticated
  isAuthenticated = () => {
    return new Promise(resolve => {
      const userPool = new CognitoUserPool(POOL_DATA)
      const cognitoUser = userPool.getCurrentUser()

      if (cognitoUser !== null) {
        cognitoUser.getSession((err, session) => {
          if (err) {
            console.error(err.message || JSON.stringify(err))
            return
          }
          this.startAutoLogoutTimer(false)
          resolve(session.accessToken.jwtToken)
        })
      } else {
        resolve(false)
      }
    })
  }

  //AUTO LOGOUT FUNCTIONALITY
  get lastAction() {
    return parseInt(store.get(STORE_KEY))
  }
  set lastAction(value) {
    store.set(STORE_KEY, value)
  }
  clearAutoLogoutTimer = () => {
    clearInterval(this.autoLogoutTimer)
  }

  startAutoLogoutTimer = shouldResetTime => {
    this.initListener()
    this.initInterval()
    if (shouldResetTime) {
      this.resetAutoLogoutTime()
    }
  }
  initListener = () => {
    document.body.addEventListener('click', () => this.resetAutoLogoutTime())
  }
  resetAutoLogoutTime = () => {
    this.lastAction = Date.now()
  }
  initInterval = () => {
    this.autoLogoutTimer = setInterval(() => {
      this.checkAutoLogout()
    }, CHECK_INTERVAL)
  }
  checkAutoLogout = () => {
    const now = Date.now()
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000
    const diff = timeleft - now
    const isTimeout = diff < 0

    if (isTimeout) {
      this.logout()
    }
  }
}
