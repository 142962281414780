import React from 'react'
import styles from './passwordrules.module.css'
import { Check, X } from '../icons'

const PasswordRules = (props) => {
  let rules = []
  Object.assign(rules, props.rules)
  let xfill = '#999999'
  if (props.password.length) {
    xfill = '#ED384E'
  }

  for (var r = 0; r < rules.length; r++) {
    const isValid = props.password.match(rules[r].regex)
    if (isValid) {
      rules[r].isValid = true
    } else {
      rules[r].isValid = false
    }
  }

  const renderedRules = rules.map((rule, index) => {
    return (
      <div key={index} className={styles.password_rule}>
        { rule.isValid ? <Check /> : <X fill={xfill} />}
        <span className={styles.password_rule_description}>{rule.description}</span>
      </div>
    )
  })

  return (
    <div className={styles.rules}>
      <span className={styles.header}>Passwords must have...</span>
      {renderedRules}
    </div>
  )
}

export default PasswordRules
