import React from 'react'
import {
  VStack,
  Container,
  GridItem,
  Box,
  Text,
  Image,
  Flex,
  Heading,
  useBoolean
} from '@chakra-ui/react'
import {
  Button,
  LayoutGrid,
  TextField
} from '@bit/blueprint-health.joy.library'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'

import BlueprintLogoRound from '../../images/blueprint_logo_round.svg'

const AppInviteSchema = Yup.object().shape({
  phoneNumber: Yup.string()
  .phone('US', true, 'Invalid U.S. phone number.')
})

function sendBranchSMS(form) {
  return new Promise((resolve, reject) => {
    const phone = form.phoneNumber;
    const linkData = {
        tags: [],
        channel: 'Website',
        feature: 'TextMeTheApp',
        data: {}
    };
    const options = {};
    const callback = function(err) {
        if (err) {
            reject(err)
        }
        else {
            resolve()
        }
    };
    window.branch.sendSMS(phone, linkData, options, callback);
  })
}

export const AppInvite = () => {
  const [submitted, setSubmitted] = useBoolean()

  return (
    <Container
      marginTop={{
        base: '0px',
        sm: '60px',
        md: '88px',
        lg: '120px',
        xl: '240px'
      }}
      paddingLeft={{
        base: 'small',
        sm: '0px'
      }}
      paddingRight={{
        base: 'small',
        sm: '0px'
      }}
    >
      <LayoutGrid>
        <GridItem
          colSpan={{
            base: 4,
            sm: 6,
            md: 6,
            lg: 4
          }}
          colStart={{
            sm: 2,
            md: 4,
            lg: 5
          }}
        >
          <Flex mb="xlarge" mt="medium" justify="center">
            <Image
              loading="eager"
              src={BlueprintLogoRound}
              sx={{
                width: '80px',
                height: '80px'
              }}
            />
          </Flex>
          <Heading size="lg" mb="small">Welcome to Blueprint</Heading>
          <Text mb="medium">{`Enter your phone number and we'll send you text message to download the app.`}</Text>
          <Formik
            validationSchema={AppInviteSchema}
            initialValues={{
              phoneNumber: '',
            }}
            validate={() => {}}
            onSubmit={async (values, actions) => {
              if (!submitted) {
                try {
                  await sendBranchSMS(values)
                  setSubmitted.on()
                } catch (error) {
                  console.error(error)
                  alert("Sorry, something went wrong.")
                }
              }
              actions.setSubmitting(false)
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <VStack spacing="small" mb="medium" alignItems="flex-start">
                  <Box w="100%" mb={errors.phoneNumber && touched.phoneNumber && 'small'}>
                    <Field name="phoneNumber">
                      {({ field }) => (
                        <TextField
                          {...field}
                          size="lg"
                          isRequired
                          label="Phone Number"
                          isInvalid={errors.phoneNumber && touched.phoneNumber}
                          errorText={errors.phoneNumber}
                        />
                      )}
                    </Field>
                  </Box>
                  <Box w="100%">
                    <Button
                      size="lg"
                      mt="xsmall"
                      isFullWidth
                      isLoading={isSubmitting}
                      type="submit"
                      backgroundColor={submitted && "success"}
                    >
                      {submitted ? 'SMS Sent!' : 'Send me the app'}
                    </Button>
                  </Box>
                  <Box>
                    <Text fontSize="xsm">
                    By providing your phone number, you agree to receive a one-time automated text message with a link to get the app. Standard messaging rates may apply.
                    </Text>
                  </Box>
                </VStack>
              </Form>
            )}
          </Formik>
        </GridItem>
      </LayoutGrid>
    </Container>
  )
}