export const themes = {
  main: {
    selectedDayColor: '#2c54e8',
    fontFamily: 'inherit'
  },
  black: {
    hideNav: true,
    selectedDayColor: '#000000',
    fontFamily: 'Nolan Next',
    styles: {
      notificationPreferences: {
        container: {
          '& *': {
            textAlign: 'left !important',
          },
  
          '& h3': {
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: 1.5,
            opacity: 1,
          }
        },
        title: {
          fontSize: 25,
          fontWeight: 500,
        },
        day: {
          fontWeight: 'bold',
          fontSize: 14
        },
        timePicker: {
          left: 0
        },
        timepickerContainer: {
          display: 'flex',
          justifyContent: 'flex-start'
        }
      },
      forgotPassword: {
        page: {
          '& *': {
            fontFamily: 'Nolan Next'
          },
          '& button': {
            backgroundColor: '#39ABD1 !important'
          }
        },
      }
    }
  }
}
