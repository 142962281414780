/* eslint-disable no-control-regex */
import React, { Component } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import styles from './enroll.module.css'
import { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isMobileOnly } from 'react-device-detect'
import {
  MenuItem,
  Checkbox,
  TextField,
  Select,
  FormControl,
  InputLabel,
  FormHelperText
} from '@material-ui/core'
import { PrimaryButton, Loading, BPDatePicker } from '../../components'
import queryString from 'query-string'
import * as Copy from '../../copy'
import moment from 'moment'
import flagsmith from 'flagsmith'
import InputMask from 'react-input-mask'

const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
const dobRegex = /^(0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])[/-]\d{4}$/

const transformPhoneNumber = phoneNumber => {
  return phoneNumber.length ? `+1${phoneNumber.replace(/\D/g, '')}` : null
}

const validators = {
  clinician: {
    isInvalid: value => !value,
    error: '*Please select a clinician.'
  },
  firstName: {
    isInvalid: value => !value,
    error: '*Please enter a valid first name.'
  },
  lastName: {
    isInvalid: value => !value,
    error: '*Please enter a valid last name.'
  },
  dateOfBirth: {
    isInvalid: value =>
      !dobRegex.test(value) ||
      moment(value).isAfter(moment.now()) ||
      moment(value).isBefore(moment().subtract(130, 'years')),
    error: '*Please enter a valid date of birth.'
  },
  phoneNumber: {
    isInvalid: value =>
      value && value.length && !isValidPhoneNumber(transformPhoneNumber(value)),
    error: '*That phone number is invalid.'
  },
  email: {
    isInvalid: (value) => value && value.length && !emailRegex.test(value),
    error: "*That email address is invalid."
  },
  mrn: {
    isInvalid: (value) => !value,
    error: '*Please enter your Medical Record Number'
  }
}

class Basics extends Component {
  constructor(props) {
    super(props)

    this.checkbox = React.createRef()
    this.clinicianInput = React.createRef()
    this.firstNameInput = React.createRef()
    this.lastNameInput = React.createRef()
    this.dobInput = React.createRef()
    this.phoneInput = React.createRef()
    this.emailInput = React.createRef()

    this.state = {
      clinicianDisplayId: this.props.computedMatch.params.clinician_display_id,
      clinicianDisplayName: null,
      clinicianId: '',
      clinicians: [],
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      email: '',
      invalidDisplayId: false,
      showExtraFields: false,
      errors: {},
      loading: true,
      submitting: false,
      notifyClinician: true,
      mrn: '',
      mrnMode: false
    }
  }

  scrollToTop = behavior => {
    if (this.containerRef && this.containerRef.current) {
      this.containerRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior
      })
    }
  }

  componentDidMount() {
    if (this.props.isClinic) {
      this.fetchClinicianList()
    } else {
      this.fetchClinician()
    }
  }

  navigateToAssessments = (patientId, extraStateParams) => {
    this.props.history.push(
      `/patients/${patientId}/assessments/0?source=kiosk`,
      Object.assign(
        {
          origin: this.props.location.pathname // used to replace history later in order to prevent the back button from going to patients' assessments
        },
        extraStateParams
      )
    )
  }

  getInputError = () => {
    const {
      firstName,
      lastName,
      email,
      dateOfBirth,
      phoneNumber,
      clinicianId,
      showExtraFields
    } = this.state
    let error, field

    if (this.props.isClinic && validators.clinician.isInvalid(clinicianId)) {
      error = validators.clinician.error
      this.clinicianInput.current.focus()
      field = 'clinician'
    } else if (validators.firstName.isInvalid(firstName)) {
      error = validators.firstName.error
      this.firstNameInput.current.focus()
      field = 'firstName'
    } else if (validators.lastName.isInvalid(lastName)) {
      error = validators.lastName.error
      this.lastNameInput.current.focus()
      field = 'lastName'
    } else if (validators.dateOfBirth.isInvalid(dateOfBirth)) {
      error = validators.dateOfBirth.error
      this.dobInput.current.focus()
      field = 'dateOfBirth'
    } else if (
      showExtraFields &&
      validators.phoneNumber.isInvalid(transformPhoneNumber(phoneNumber))
    ) {
      error = validators.phoneNumber.error
      this.phoneInput.current.focus()
      field = 'phoneNumber'
    } else if (showExtraFields && validators.email.isInvalid(email)) {
      error = validators.email.error
      this.emailInput.current.focus()
      field = 'email'
    }
    return { error, field }
  }

  displayError = (message, type) => {
    this.setState({
      errors: {
        ...this.state.errors,
        [type]: message
      },
      loading: type === 'connection' ? false : this.state.loading,
      submitting: false
    })
    if (type !== 'connection') {
      this.scrollToTop('smooth')
    }
  }

  isFormComplete = () => {
    const {
      firstName,
      lastName,
      dateOfBirth,
      clinicianId,
      email,
      phoneNumber
    } = this.state
    return (
      firstName &&
      lastName &&
      dateOfBirth &&
      clinicianId &&
      email &&
      phoneNumber
    )
  }

  fetchClinician = () => {
    const {
      clinician_display_id: clinicianDisplayId
    } = this.props.computedMatch.params
    const { api, accessToken, user_role } = this.props
    const path =
      process.env.REACT_APP_NODE_API_ROOT_URL +
      '/clinician?display_id=' +
      clinicianDisplayId

    return api
      .GET(accessToken, path, user_role)
      .then(response => {
        if (response.display_name) {
          const { clinic_id, user_role, first_name, last_name, id } = response
          flagsmith.identify(id)
          flagsmith.setTraits({
            clinic_id,
            user_role,
            full_name: `${first_name} ${last_name}`
          })
          this.setState({
            clinicianDisplayName: response.display_name,
            clinicianId: response.id,
            loading: false
          })
        } else {
          this.setState({ invalidDisplayId: true, loading: false })
        }
      })
      .catch(() => {
        this.displayError(
          'Oops, something went wrong. Please refresh the page and try again.',
          'connection'
        )
      })
  }

  fetchClinicianList = () => {
    const { clinicDisplayId } = this.props.match.params
    const { api, accessToken, user_role } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/v1/clinics/clinicians?clinic_display_id=${clinicDisplayId}&show_visible_only=true`

    return api
      .GET(accessToken, path, user_role)
      .then(response => {
        if (response.clinicians) {
          this.setState({ clinicians: response.clinicians, loading: false })
        } else {
          this.setState({ invalidDisplayId: true, loading: false })
        }
      })
      .catch(() => {
        this.displayError(
          'Oops, something went wrong. Please refresh the page and try again.',
          'connection'
        )
      })
  }

  processPatient = async event => {
    if (event) {
      event.preventDefault()
    }
    if (this.state.submitting) {
      return
    }

    const {
      showExtraFields,
      firstName,
      lastName,
      dateOfBirth,
      clinicianId,
      email,
      phoneNumber,
      notifyClinician
    } = this.state
    const DoB = moment(dateOfBirth).format('YYYY-MM-DD')
    const phoneNumberTransformed = transformPhoneNumber(phoneNumber)
    this.setState({ submitting: true })

    const { error, field } = this.getInputError()
    if (error) {
      this.displayError(error, field)
      return
    }

    const { api, accessToken } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients`

    let signUpSource = isMobileOnly
      ? 'self_enroll_mobile'
      : 'self_enroll_tablet'
    signUpSource = queryString.parse(this.props.location.search).source
      ? queryString.parse(this.props.location.search).source
      : signUpSource

    const body = JSON.stringify({
      should_return_exceptions: !showExtraFields,
      first_name: firstName,
      last_name: lastName,
      date_of_birth: DoB,
      clinician_id: clinicianId,
      email,
      phone_number: phoneNumberTransformed,
      sign_up_source: signUpSource
    })

    try {
      const response = await api.POST(accessToken, path, body)
      this.setState({ submitting: false })
      const { error, data } = response
      if (data) {
        const { message, patient_id, action_taken, comms_sent } = data

        if (message === 'more_info_needed') {
          this.setState({ showExtraFields: true }, () => {
            this.phoneInput.current.focus()
          })
        }

        if (message === 'try_mrn') this.setState({ mrnMode: true })

        if (patient_id) {
          return this.navigateToAssessments(patient_id, {
            isNewPatient: action_taken.includes('NEW'),
            comms_sent,
            action_taken,
            notifyClinician: this.checkbox.current && notifyClinician
          })
        }
      }
      if (error) {
        this.displayError(error, 'patient_not_found')
      }
    } catch (err) {
      console.error(err)
    }
  }

  processMRNPatient = async event => {
    if (event) { event.preventDefault() }
    if (this.state.submitting) { return }

    const { clinicianId, mrn } = this.state
    this.setState({ submitting: true })

    const trimmedMRN = mrn.trim()
    if (validators.mrn.isInvalid(trimmedMRN)) {
      this.setState({ submitting: false })
      this.displayError(validators.mrn.error, 'mrn')
      return
    }

    const { api, accessToken } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/mrn`
    const body = JSON.stringify({
      clinicianId,
      mrn: trimmedMRN
    })

    try {
      const response = await api.POST(accessToken, path, body)
      this.setState({ submitting: false })
      const { error, patientId } = response
      if (patientId) return this.navigateToAssessments(patientId)
      if (error) this.displayError(error, 'patient_not_found')
    } catch (err) {
      console.error(err)
    }
  }

  // this is nifty
  handleChange = name => event => {
    let value
    if (event && event.target) {
      value = event.target.value
    } else {
      value = event
    }

    const isError = validators[name].isInvalid(value)
    let error = null
    if (isError) {
      error = validators[name].error
    }

    // force sign up errors to reset when any fields are changed
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: error,
        patient_not_found: '',
        sign_up: ''
      }
    })
  }

  createTextField = (id, label, props) => {
    const value = this.state[id]
    const error = this.state.errors[id]

    return (
      <div className={styles.input_div}>
        <TextField
          error={error}
          helperText={error}
          className={styles.input}
          id={id}
          label={label}
          onChange={this.handleChange(id)}
          value={value}
          variant="outlined"
          {...props}
        />
      </div>
    )
  }

  onDateAccept = date => {
    this.setState({ dateOfBirth: date.toISOString() }, this.processPatient)
  }

  handleClinicianOpen = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClinicianClose = () => {
    this.setState({ anchorEl: null })
  }

  handleClinicianClick = event => {
    const clinicianId = event.target.value
    const selectedClinician = this.state.clinicians.find(
      clinician => clinician.id === clinicianId
    )

    const {
      first_name,
      last_name,
      display_id: clinicianDisplayId,
      clinic_id,
      user_role
    } = selectedClinician
    const clinicianDisplayName = `${first_name} ${last_name}`
    flagsmith.identify(clinicianId)
    flagsmith.setTraits({
      clinic_id,
      user_role,
      full_name: `${first_name} ${last_name}`
    })

    this.setState({
      clinicianId,
      clinicianDisplayName,
      clinicianDisplayId,
      errors: {
        ...this.state.errors,
        clinician: null
      }
      // showExtraFields: false
    }) // reset new patient fields in case user chose the wrong one
    this.handleClinicianClose()
  }

  handleCheckboxClick = () => {
    this.setState({ notifyClinician: !this.state.notifyClinician })
  }

  renderErrorWithContactUs = () => {
    const { errors } = this.state
    const parts = errors.patient_not_found.split('contact us')
    return parts.length === 1 ? (
      errors.patient_not_found
    ) : (
      <span>
        {parts[0]}
        {this.renderContactUs()}
        {parts[1]}
      </span>
    )
  }

  renderContactUs = () => {
    return (
      <a
        className={styles.error_link}
        href="mailto:clients@blueprint-health.com"
      >
        contact us
      </a>
    )
  }

  getDateWithoutTime = dateTimeString => {
    return dateTimeString.substring(0, dateTimeString.indexOf('T'))
  }

  renderDatePicker = () => {
    const { dateOfBirth } = this.state
    return (
      <div className={styles.input_div}>
        <BPDatePicker
          error={!!this.state.errors.dateOfBirth}
          helperText={this.state.errors.dateOfBirth}
          inputRef={this.dobInput}
          id="dob"
          value={dateOfBirth}
          onChange={this.handleChange('dateOfBirth')}
          placeholder="Date of Birth"
          required
          styles={{
            input: {
              width: '100% !important'
            }
          }}
        />
      </div>
    )
  }

  renderContent = () => {
    const {
      clinicianDisplayName,
      errors,
      submitting,
      clinicians,
      clinicianId,
      showExtraFields,
      notifyClinician
    } = this.state
    const { isClinic } = this.props
    const useColumnLayout = isMobileOnly || this.props.isClinic

    return (
      <div className={styles.page}>
        {submitting && <Loading className={styles.submitting_spinner} />}

        <div ref={this.containerRef} className={styles.container}>
          <div className={styles.container_inner}>
            <div className={styles.title}>
              <h1>Welcome to Blueprint</h1>
            </div>

            {errors.connection && (
              <p className={styles.connection_error}>{errors.connection}</p>
            )}

            <p className={styles.intro_text}>
              {isClinic ? 'Your clinician' : clinicianDisplayName} uses
              Blueprint to measure your progress throughout treatment and ensure
              you&apos;re on the right track.
            </p>

            <p className={styles.basics_text} style={{ marginTop: 12 }}>
              * - indicates required fields
            </p>

            {!isClinic && (
              <>
                <div className={styles.number_title}>
                  <div className={styles.number_background}>
                    <p>1</p>
                  </div>
                  <p className={styles.the_basics}>
                    {this.state.mrnMode ? "Enter your Medical Record Number" : "Basic Information"}
                  </p>
                </div>

                <p className={styles.basics_text}>
                  {this.state.mrnMode ? "Enter your Medical Record Number so we can match you up with the correct assessments." : "Fill out the following fields so we can match you up with the correct assessments."}
                </p>
              </>
            )}

            {(errors.sign_up || errors.patient_not_found) && ( // not sure if errors.sign_up is still used, just removed it and added a little fallback
              <p className={styles.error_margin_bottom} role="alert">
                {errors.patient_not_found
                  ? this.renderErrorWithContactUs()
                  : errors.sign_up}
              </p>
            )}

            {this.state.mrnMode
            ? <form className={styles.form} onSubmit={this.processMRNPatient} autoComplete="off">
              {this.createTextField('mrn', 'Medical Record Number', {
                autoFocus: !isClinic,
                required: true,
                inputRef: this.mrnInput,
              })}
              <input type="submit" hidden />
            </form>

            : <form className={styles.form} onSubmit={this.processPatient} autoComplete='off'>
              <div
                className={
                  useColumnLayout ? styles.form_column : styles.form_row
                }
              >
                {isClinic && (
                  <FormControl
                    required
                    variant={'outlined'}
                    error={this.state.errors.clinician}
                  >
                    <InputLabel>Clinician</InputLabel>
                    <Select
                      label={'Clinician'}
                      value={clinicianId}
                      inputRef={this.clinicianInput}
                      onChange={this.handleClinicianClick}
                    >
                      {clinicians.map(clinician => (
                        <MenuItem key={clinician.id} value={clinician.id}>
                          {clinician.first_name} {clinician.last_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {this.state.errors.clinician}
                    </FormHelperText>
                  </FormControl>
                )}
                {this.createTextField('firstName', 'First name', {
                  autoFocus: !isClinic,
                  required: true,
                  inputRef: this.firstNameInput
                })}
                {this.createTextField('lastName', 'Last name', {
                  required: true,
                  inputRef: this.lastNameInput
                })}

                {this.renderDatePicker()}
              </div>
              {showExtraFields && (
                <div
                  className={
                    useColumnLayout ? styles.form_column : styles.form_row
                  }
                >
                  <InputMask
                    mask="(999) 999-9999"
                    value={this.state.phoneNumber}
                    onChange={this.handleChange('phoneNumber')}
                  >
                    {() => (
                      <div className={styles.input_div}>
                        <TextField
                          error={this.state.errors.phoneNumber}
                          helperText={this.state.errors.phoneNumber}
                          className={styles.input}
                          id="phoneNumber"
                          label="Phone number"
                          variant="outlined"
                          inputRef={this.phoneInput}
                        />
                      </div>
                    )}
                  </InputMask>
                  {this.createTextField('email', 'Email', {
                    inputRef: this.emailInput
                  })}
                </div>
              )}
              {flagsmith.hasFeature('kiosk_arrival_checkbox') && (
                <span className={styles.notifyClinician}>
                  <Checkbox
                    ref={this.checkbox}
                    onChange={this.handleCheckboxClick}
                    checked={notifyClinician}
                    value={notifyClinician}
                    inputProps={{ 'aria-labelledby': 'notifyClinicianText' }}
                  />
                  <div id="notifyClinicianText">
                    {Copy.notifyYourClinician()}
                  </div>
                </span>
              )}
              <input type="submit" hidden />
            </form>}
            {isMobileOnly && this.renderFooter()}
          </div>
        </div>
        {isMobileOnly ? null : this.renderFooter()}
      </div>
    )
  }

  renderFooter = () => {
    return (
      <div className={styles.footer}>
        <div className={styles.footer_button_container}>
          <PrimaryButton
            className={styles.action}
            round={true}
            onClick={this.state.mrnMode ? this.processMRNPatient : this.processPatient}
          >
            Next
          </PrimaryButton>
          <div className={styles.policy_terms_statement}>
            By continuing, you agree to our{' '}
            <a
              className={styles.policy_terms}
              rel="noopener noreferrer"
              target="_blank"
              href="https://blueprint-health.com/privacy"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              className={styles.policy_terms}
              rel="noopener noreferrer"
              target="_blank"
              href="https://blueprint-health.com/terms"
            >
              Terms of Service.
            </a>{' '}
            {this.state.showExtraFields && (
              <span>{Copy.contactInfoSuggestion()}</span>
            )}
          </div>
        </div>
      </div>
    )
  }

  renderInvalidDisplayId = () => {
    return <Redirect to={'/invalid-url'} />
  }

  render() {
    if (this.state.loading) {
      return <Loading />
    }
    if (this.state.invalidDisplayId) {
      return this.renderInvalidDisplayId()
    }
    return this.renderContent()
  }
}

export default withRouter(Basics)
