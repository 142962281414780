import React from 'react'
import {
  ForgotPassword,
  ForgotPasswordCode,
  UnsubscribeSuccessful,
  NotificationPreferences,
  InvalidURL,
  IntakeSuccess,
  AppInvite
} from './handlers'
import { Navigation } from './components'
import { EnrollAssessmentCarousel } from './handlers/enroll'
import { Switch, Router, Route, Redirect } from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ThemeProvider } from '@material-ui/core/styles'
import { themes } from './theme'
import Auth from './auth'
import history from './history'
import Api from './api'
import * as Sentry from '@sentry/browser'
const auth = new Auth()
const api = new Api()

const Loading = () => (
  <div style={{ display: 'flex', height: '100%' }}>
    <CircularProgress style={{ margin: 'auto' }} color="primary" />
  </div>
)

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error)

    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div>
          <h2 style={{ maxWidth: '375px', margin: '100px auto', color: 'red' }}>
            This page was unable to load. Please refresh your browser page to
            try again.
            <br />
            If the page still doesn&apos;t load, send us an email
            clients@blueprint-health.com -- we&apos;re here to help!
          </h2>
        </div>
      )
    }

    return this.props.children
  }
}

class PublicRoute extends React.Component {
  state = {
    loading: true,
    isLoggedIn: false
  }

  componentDidMount() {
    this.checkAuth()
  }

  UNSAFE_componentWillReceiveProps() {
    this.checkAuth()
  }

  checkAuth = () => {
    auth.isAuthenticated().then(accessToken => {
      this.setState({
        loading: false,
        isLoggedIn: accessToken ? true : false
      })
    })
  }

  render() {
    const { component: Component, ...rest } = this.props


    const queryParams = new URLSearchParams(this.props.location.search)
    const themeQueryParam =
      queryParams.get('theme') || 'main'
    const theme = themes[themeQueryParam]

    if (this.state.loading) {
      return <Loading />
    } else {
      return (
        <ErrorBoundary>
          <Route
            {...rest}
            render={() =>
              this.state.isLoggedIn ? (
                <Redirect to={{ pathname: '/' }} />
              ) : (
                <div style={{ width: '100%', height: '100%', paddingTop: this.props.hideNav ? '0px' : '66px' }}>
                  {!this.props.hideNav && !theme.hideNav &&
                    <Navigation
                      accessToken={null}
                      api={this.props.api}
                      auth={this.props.auth}
                    />
                  }
                  {/* temporary way to override styles on some pages */}
                  <ThemeProvider theme={theme}>
                    <Component auth={auth} api={api} {...this.props} />
                  </ThemeProvider>
                </div>
              )
            }
          />
        </ErrorBoundary>
      )
    }
  }
}

const Routes = () => (
  <Router history={history}>
    <div className="Page">
      <Switch>
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute
          path="/forgot-password-code"
          component={ForgotPasswordCode}
        />
        <Redirect
          from="/enroll/:clinician_display_id"
          to="/clinicians/:clinician_display_id"
        />
        <PublicRoute
          path="/clinicians/:clinician_display_id"
          component={(props) => {
            window.location.href = `${process.env.REACT_APP_CLIENT_WEB_URL}/clinicians/${props.computedMatch.params.clinician_display_id}`
            return null
          }}
        />
        <PublicRoute
          path="/clinics/:clinicDisplayId"
          component={(props) => {  
            window.location.href = `${process.env.REACT_APP_CLIENT_WEB_URL}/clinics/${props.computedMatch.params.clinicDisplayId}`
            return null
          }}
          isClinic
        />
        <PublicRoute
          path="/patients/:patient_id/notification-preferences"
          component={NotificationPreferences}
          hideNav
        />
        <Redirect
          exact
          from="/patients/:patient_id"
          to="/patients/:patient_id/assessments"
        />
        <PublicRoute
          path="/patients/:patient_id/assessments"
          component={EnrollAssessmentCarousel}
        />
        <PublicRoute
          path="/unsubscribe-successful"
          component={UnsubscribeSuccessful}
        />
        <PublicRoute path="/invalid-url" component={InvalidURL} />
        <PublicRoute path="/intake-success" component={IntakeSuccess} />
        <PublicRoute exact path="/app-invite" component={AppInvite} hideNav />
        <PublicRoute
          path="/"
          component={() => {
            window.location.href =
              'https://app.blueprint-health.com/clinics/bph'
            return null
          }}
        />
      </Switch>
    </div>
  </Router>
)

export default Routes
