import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = props => (
  <div style={{display: 'flex', height: '100%'}} className={props.className} >
    <CircularProgress style={{margin: 'auto'}} color="primary" />
  </div>
)

export default Loading
