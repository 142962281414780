const FinalCopyCode = {
  NEW_PHONE_EMAIL_KIOSK_MOBILE: 'NEW_PHONE_EMAIL_KIOSK_MOBILE',
  NEW_PHONE_KIOSK_MOBILE: 'NEW_PHONE_KIOSK_MOBILE',
  NEW_EMAIL_KIOSK_MOBILE: 'NEW_EMAIL_KIOSK_MOBILE',
  NEW_NONE_KIOSK_MOBILE: 'NEW_NONE_KIOSK_MOBILE',
  UNARCHIVED_PHONE_EMAIL_KIOSK_MOBILE: 'UNARCHIVED_PHONE_EMAIL_KIOSK_MOBILE',
  UNARCHIVED_PHONE_KIOSK_MOBILE: 'UNARCHIVED_PHONE_KIOSK_MOBILE',
  UNARCHIVED_EMAIL_KIOSK_MOBILE: 'UNARCHIVED_EMAIL_KIOSK_MOBILE',
  UNARCHIVED_NONE_KIOSK_MOBILE: 'UNARCHIVED_NONE_KIOSK_MOBILE',
  EXISTING_PHONE_EMAIL_KIOSK_MOBILE: 'EXISTING_PHONE_EMAIL_KIOSK_MOBILE',
  EXISTING_PHONE_KIOSK_MOBILE: 'EXISTING_PHONE_KIOSK_MOBILE',
  EXISTING_EMAIL_KIOSK_MOBILE: 'EXISTING_EMAIL_KIOSK_MOBILE',
  EXISTING_NONE_KIOSK_MOBILE: 'EXISTING_NONE_KIOSK_MOBILE',
  EXISTING_PHONE_EMAIL_SMS_MOBILE: 'EXISTING_PHONE_EMAIL_SMS_MOBILE',
  EXISTING_PHONE_EMAIL_EMAIL_MOBILE: 'EXISTING_PHONE_EMAIL_EMAIL_MOBILE',
  EXISTING_PHONE_EMAIL_MANUAL_EMAIL_MOBILE:
    'EXISTING_PHONE_EMAIL_MANUAL_EMAIL_MOBILE',
  EXISTING_PHONE_EMAIL_MANUAL_SMS_MOBILE:
    'EXISTING_PHONE_EMAIL_MANUAL_SMS_MOBILE',
  EXISTING_PHONE_SMS_MOBILE: 'EXISTING_PHONE_SMS_MOBILE',
  EXISTING_PHONE_MANUAL_SMS_MOBILE: 'EXISTING_PHONE_MANUAL_SMS_MOBILE',
  EXISTING_EMAIL_EMAIL_MOBILE: 'EXISTING_EMAIL_EMAIL_MOBILE',
  EXISTING_EMAIL_MANUAL_EMAIL_MOBILE: 'EXISTING_EMAIL_MANUAL_EMAIL_MOBILE',
  NEW_PHONE_EMAIL_KIOSK_TABLET: 'NEW_PHONE_EMAIL_KIOSK_TABLET',
  NEW_PHONE_KIOSK_TABLET: 'NEW_PHONE_KIOSK_TABLET',
  NEW_EMAIL_KIOSK_TABLET: 'NEW_EMAIL_KIOSK_TABLET',
  NEW_NONE_KIOSK_TABLET: 'NEW_NONE_KIOSK_TABLET',
  UNARCHIVED_PHONE_EMAIL_KIOSK_TABLET: 'UNARCHIVED_PHONE_EMAIL_KIOSK_TABLET',
  UNARCHIVED_PHONE_KIOSK_TABLET: 'UNARCHIVED_PHONE_KIOSK_TABLET',
  UNARCHIVED_EMAIL_KIOSK_TABLET: 'UNARCHIVED_EMAIL_KIOSK_TABLET',
  UNARCHIVED_NONE_KIOSK_TABLET: 'UNARCHIVED_NONE_KIOSK_TABLET',
  EXISTING_PHONE_EMAIL_KIOSK_TABLET: 'EXISTING_PHONE_EMAIL_KIOSK_TABLET',
  EXISTING_PHONE_KIOSK_TABLET: 'EXISTING_PHONE_KIOSK_TABLET',
  EXISTING_EMAIL_KIOSK_TABLET: 'EXISTING_EMAIL_KIOSK_TABLET',
  EXISTING_NONE_KIOSK_TABLET: 'EXISTING_NONE_KIOSK_TABLET',
  EXISTING_PHONE_EMAIL_SMS_TABLET: 'EXISTING_PHONE_EMAIL_SMS_TABLET',
  EXISTING_PHONE_EMAIL_EMAIL_TABLET: 'EXISTING_PHONE_EMAIL_EMAIL_TABLET',
  EXISTING_PHONE_EMAIL_MANUAL_EMAIL_TABLET:
    'EXISTING_PHONE_EMAIL_MANUAL_EMAIL_TABLET',
  EXISTING_PHONE_EMAIL_MANUAL_SMS_TABLET:
    'EXISTING_PHONE_EMAIL_MANUAL_SMS_TABLET',
  EXISTING_PHONE_SMS_TABLET: 'EXISTING_PHONE_SMS_TABLET',
  EXISTING_PHONE_MANUAL_SMS_TABLET: 'EXISTING_PHONE_MANUAL_SMS_TABLET',
  EXISTING_EMAIL_EMAIL_TABLET: 'EXISTING_EMAIL_EMAIL_TABLET',
  EXISTING_EMAIL_MANUAL_EMAIL_TABLET: 'EXISTING_EMAIL_MANUAL_EMAIL_TABLET',
  EXISTING_PHONE_EMAIL_KIOSK_NO_OVERDUE_TABLET:
    'EXISTING_PHONE_EMAIL_KIOSK_NO_OVERDUE_TABLET',
  NO_OVERDUE: 'NO_OVERDUE',
  NO_ASSESSMENTS: 'NO_ASSESSMENTS'
}
Object.freeze(FinalCopyCode)
export default FinalCopyCode
