import React, { Component } from 'react'
import { CircleCheck, Apple, GooglePlay } from '../../components/icons'
import styles from './final.module.css'
import { isMobileOnly, isAndroid } from 'react-device-detect'
import { PrimaryButton } from '../../components'
import * as Copy from '../../copy'
import FinalCopyCode from '../../codes'

export default class Final extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotRightNowSelected: false,
      branchLink: '',
      sendingLink: false,
      linkSent: false,
      errors: {}
    }
  }

  componentDidMount = () => {
    if (isMobileOnly) {
      this.getBranchLink()
    }
  }

  navigateToBranchLink = () => {
    const { branchLink } = this.state
    window.location = branchLink
  }

  getBranchLink = () => {
    const { api, patientId } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/branch-link`

    return api
      .GET(null, path)
      .then(response => {
        this.setState({
          branchLink: response.branch_url
        })
      })
      .catch(err => {
        this.setState({
          errors: { ...this.state.errors, branch: err }
        })
      })
  }

  sendBranchLink = () => {
    const { api, patientId } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/branch-link`

    this.setState({ sendingLink: true })
    return api
      .POST(null, path)
      .then(response => {
        if (response.error) {
          this.setState({
            sendingLink: false,
            errors: { ...this.state.errors, branch: response.error }
          })
        } else {
          this.setState({ sendingLink: false, linkSent: true })
          setTimeout(() => {
            this.setState({ linkSent: false })
          }, 3000)
        }
      })
      .catch(err => {
        this.setState({
          sendingLink: false,
          errors: { ...this.state.errors, branch: err }
        })
      })
  }

  createInstructionCode = () => {
    const {
      actionTaken,
      hasAssessments,
      hasEmail,
      hasOverdue,
      hasPhone,
      source,
    } = this.props
    let patientStatus = 'EXISTING'
    let device = 'TABLET'
    let contactInfo = 'NONE'
    if (!hasAssessments) return 'NO_ASSESSMENTS'
    if (!hasOverdue) return 'NO_OVERDUE'
    if (hasPhone && hasEmail) contactInfo = 'PHONE_EMAIL'
    if (hasPhone && !hasEmail) contactInfo = 'PHONE'
    if (!hasPhone && hasEmail) contactInfo = 'EMAIL'
    if (actionTaken) {
      if (actionTaken.includes('UNARCHIVE')) patientStatus = 'UNARCHIVED'
      if (actionTaken.includes('NEW_PATIENT')) patientStatus = 'NEW'
    }
    if (isMobileOnly) device = 'MOBILE'
    return `${patientStatus}_${contactInfo}_${source}_${device}`
  }

  // ex. NEW_PHONE_EMAIL_KIOSK_TABLET

  renderTitle = copyCode => {
    switch (copyCode) {
      case FinalCopyCode.NO_ASSESSMENTS:
      case FinalCopyCode.NO_OVERDUE:
        return "You're all set!"
      default:
        return 'Assessment Complete!'
    }
  }

  renderCopy = copyCode => {
    switch (copyCode) {
      case FinalCopyCode.UNARCHIVED_PHONE_KIOSK_MOBILE:
      case FinalCopyCode.UNARCHIVED_EMAIL_KIOSK_MOBILE:
      case FinalCopyCode.UNARCHIVED_NONE_KIOSK_MOBILE:
      case FinalCopyCode.UNARCHIVED_PHONE_KIOSK_TABLET:
      case FinalCopyCode.UNARCHIVED_EMAIL_KIOSK_TABLET:
      case FinalCopyCode.UNARCHIVED_NONE_KIOSK_TABLET:
        return Copy.unarchivedThanks()
      case FinalCopyCode.NEW_PHONE_EMAIL_KIOSK_MOBILE:
        return Copy.newInviteMobile(this.props.clinicianDisplayName, isAndroid)
      case FinalCopyCode.UNARCHIVED_PHONE_EMAIL_KIOSK_MOBILE:
        return Copy.unarchivedInviteMobile(
          this.props.clinicianDisplayName,
          isAndroid
        )
      case FinalCopyCode.EXISTING_PHONE_EMAIL_KIOSK_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_SMS_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_EMAIL_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_EMAIL_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_SMS_MOBILE:
        return Copy.existingInviteMobile(isAndroid)
      case FinalCopyCode.NEW_PHONE_EMAIL_KIOSK_TABLET:
        return Copy.newInviteTablet(
          this.props.clinicianDisplayName,
          this.props.smsSendable
        )
      case FinalCopyCode.UNARCHIVED_PHONE_EMAIL_KIOSK_TABLET:
        return Copy.unarchivedInviteTablet()
      case FinalCopyCode.EXISTING_PHONE_EMAIL_KIOSK_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_SMS_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_EMAIL_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_EMAIL_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_SMS_TABLET:
        return Copy.existingInviteTablet()
      case FinalCopyCode.NO_ASSESSMENTS:
      case FinalCopyCode.NO_OVERDUE:
        return Copy.noOverdue()
      default:
        return Copy.defaultThanks(this.props.clinicianDisplayName)
    }
  }

  renderSuccessLinks = copyCode => {
    switch (copyCode) {
      case FinalCopyCode.NO_OVERDUE: {
        return (
          <>
            <p
              className={styles.enroll_another}
              onClick={this.props.handleTakeAssessmentsAnyway}
              tabIndex="0"
              role="button"
              onKeyDown={e => {
                const key = e.which
                if (key === 13 || key === 32)
                  this.props.handleTakeAssessmentsAnyway()
              }}
            >
              Take assessment anyway
            </p>
            {this.props.navigateToHomeScreen && (
              <p
                className={styles.enroll_another}
                onClick={this.props.navigateToHomeScreen}
                tabIndex="0"
                role="button"
                onKeyDown={e => {
                  const key = e.which
                  if (key === 13 || key === 32)
                    this.props.navigateToHomeScreen()
                }}
              >
                Back to home screen
              </p>
            )}
          </>
        )
      }
      case FinalCopyCode.NEW_PHONE_EMAIL_KIOSK_MOBILE:
      case FinalCopyCode.UNARCHIVED_PHONE_EMAIL_KIOSK_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_KIOSK_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_SMS_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_EMAIL_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_EMAIL_MOBILE:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_SMS_MOBILE: {
        return (
          <>
            <br />
            <PrimaryButton
              className={styles.branch_link_button}
              onClick={this.navigateToBranchLink}
            >
              {isAndroid ? (
                <div className={styles.successdownloadbtn}>
                  <GooglePlay />
                  <p>Download on the Google Play Store</p>
                </div>
              ) : (
                <div className={styles.successdownloadbtn}>
                  <Apple />
                  <p>Download on the App Store</p>
                </div>
              )}
            </PrimaryButton>
          </>
        )
      }
      case FinalCopyCode.NEW_PHONE_EMAIL_KIOSK_TABLET:
      case FinalCopyCode.UNARCHIVED_PHONE_EMAIL_KIOSK_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_KIOSK_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_SMS_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_EMAIL_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_EMAIL_TABLET:
      case FinalCopyCode.EXISTING_PHONE_EMAIL_MANUAL_SMS_TABLET: {
        return (
          <>
            <PrimaryButton
              className={styles.branch_link_button}
              onClick={this.sendBranchLink}
              loading={this.state.sendingLink}
              success={this.state.linkSent}
            >
              Send a download link to my phone
            </PrimaryButton>
            {this.state.errors.branch && (
              <p className={styles.error}>*{this.state.errors.branch}</p>
            )}
            {this.props.navigateToHomeScreen && (
              <p
                className={styles.enroll_another}
                onClick={this.props.navigateToHomeScreen}
                tabIndex="0"
                role="button"
                onKeyDown={e => {
                  const key = e.which
                  if (key === 13 || key === 32)
                    this.props.navigateToHomeScreen()
                }}
              >
                Back to home screen
              </p>
            )}
          </>
        )
      }
      default: {
        return (
          this.props.navigateToHomeScreen && (
            <p
              className={styles.enroll_another}
              onClick={this.props.navigateToHomeScreen}
              tabIndex="0"
              role="button"
              onKeyDown={e => {
                const key = e.which
                if (key === 13 || key === 32) this.props.navigateToHomeScreen()
              }}
            >
              Back to home screen
            </p>
          )
        )
      }
    }
  }

  renderFinal = finalCopyCode => {
    return (
      <div className={styles.final_container}>
        <div className={styles.successContainer}>
          <div className={styles.successCheckContainer}>
            <CircleCheck className={styles.successCheck} fill="#44CD7D" />
          </div>
          <p className={styles.successtitle}>
            {this.renderTitle(finalCopyCode)}
          </p>
          {this.props.errors && this.props.errors.notification && (
            <p className={styles.error}>*{this.props.errors.notification}</p>
          )}
          <div className={styles.successtext}>
            {this.renderCopy(finalCopyCode)}
          </div>
          <div className={styles.successlinks}>
            {this.renderSuccessLinks(finalCopyCode)}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const finalCopyCode = this.createInstructionCode()
    return this.renderFinal(finalCopyCode)
  }
}
