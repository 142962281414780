import React from 'react'
import finalStyles from './handlers/enroll/enroll.module.css'

export const contactInfoSuggestion = () => {
  return 'By entering your phone number, you will receive an SMS invitation to enroll on Blueprint and consent to receiving text notifications from Blueprint to support your treatment plan. Message and data rates may apply. Message frequency varies based on your treatment plan. Text HELP for help and STOP to opt out.'
}
export const noOverdue = () => {
  return (
    <p>
      You&apos;ve already completed an assessment in the timeframe requested by
      your clinician, so we don&apos;t need anything more at this time. We appreciate you!
    </p>
  )
}
export const existingInviteMobile = (isAndroid) => {
  return (
    <>
      <p>Thanks for completing your assessment.</p>
      <p>
        If you&apos;d like to view the results on your own mobile device{!isAndroid && ` and track
        how behavioral signals like sleep, exercise, and movement impact your mental health`},
        download the
        {/* em and strong weren't working */}
        <span className={finalStyles.bold}> Blueprint mobile app</span>
        {'.'}
      </p>
    </>
  )
}
export const existingInviteTablet = () => {
  return (
    <>
      <p>Thanks for completing your assessment.</p>
      <p>
        If you&apos;d like to complete assessments on your own mobile device,
        view your results, and gain insight into sleep, exercise, and
        movement patterns, download our mobile app.
      </p>
    </>
  )
}
export const defaultThanks = (clinicianDisplayName) => {
  return (
    <p>
      Thanks for taking the time to complete this, we appreciate you. Blueprint helps {clinicianDisplayName} deliver the best care possible.
    </p>
  )
}
export const newInviteMobile = (clinicianDisplayName, isAndroid) => {
  return (
    <>
      <p>
        Thanks for taking the time to complete your assessment.{' '}
        {clinicianDisplayName} will review your results at your next
        visit.
      </p>
      <p>
        If you&apos;d like to view the results on your own mobile device{!isAndroid && ` and track
        how behavioral signals like sleep, exercise, and movement impact your mental health`},
        download the
        {/* em and strong weren't working */}
        <span className={finalStyles.bold}> Blueprint mobile app</span>
        {'.'}
      </p>
    </>
  )
}
export const newInviteTablet = (clinicianDisplayName, smsSendable) => {
  return (
    <>
      {smsSendable && <p>
        We sent you an invitation to download the
        {/* em and strong weren't working */}
        <span className={finalStyles.bold}> Blueprint mobile app</span>
        {`. Download it via the link to ensure your data is shared with ${clinicianDisplayName}.`}
      </p>}
      <p>
        With the Blueprint mobile app, you can view results, complete
        assessments on your own time between appointments, and gain insight
        into how your sleep, exercise, and movement patterns may be affecting
        your mental health.
      </p>
    </>
  )
}
export const unarchivedInviteMobile = (clinicianDisplayName, isAndroid) => {
  return (
    <>
      <p>
        Welcome back to Blueprint. Thanks for taking the time to complete your assessment.{' '}
        {clinicianDisplayName} will review your results at your upcoming
        visit.
      </p>
      <p>
        If you&apos;d like to view the results on your own mobile device{!isAndroid && ` and track
        how behavioral signals like sleep, exercise, and movement impact your mental health`},
        download the
        {/* em and strong weren't working */}
        <span className={finalStyles.bold}> Blueprint mobile app</span>
        {'.'}
      </p>
    </>
  )
}
export const unarchivedInviteTablet = () => {
  return (
    <>
      <p>Thanks for completing your assessment.</p>
      <p>
        Welcome back to Blueprint. If you&apos;d like to complete assessments on your own mobile device,
        view your results, and gain insight into sleep, exercise, and
        movement patterns, download our mobile app.
      </p>
    </>
  )
}
export const unarchivedThanks = () => {
  return (
    <>
      <p>
        Welcome back to Blueprint.
      </p>
      <p>
       Thanks for taking the time to complete this, we appreciate you. In case you don&apos;t remember,
       Blueprint helps your clinician collect valuable information about
       your health and wellness throughout treatment so they can deliver
       the best care possible.
      </p>
    </>
  )
}
export const notifyYourClinician = () => {
  return 'Notify your clinician that you\'ve arrived for your appointment'
}

export const intakeSuccessTitle = () => {
  return 'You have successfully completed the intake form.'
}
export const intakeSuccessBody = () => {
  return 'Thank you for completing the intake form, we appreciate you taking the time. The clinic will reach out to you shortly to coordinate next steps.'
}
export const intakeSuccessClinicQuestionsTitle = () => {
  return 'Questions for the clinic?'
}
export const intakeSuccessClinicQuestions = () => {
  return 'Return to the clinic\'s website and contact them directly'
}
export const intakeSuccessTechQuestionsTitle = () => {
  return 'Technical questions?'
}
export const intakeSuccessTechQuestions = () => {
  return 'clients@blueprint-health.com'
}
export const forgotPasswordResetCode = () => {
  return 'If your account was found, an email was sent with a password reset code. Please enter this code and choose a new password. If you cannot find this email, check in your spam folder.'
}
export const forgotPasswordSupport = userType => {
  let supportEmail = '@blueprint-health.com'
  
  switch(userType) {
    case 'patient':
      supportEmail = `clients${supportEmail}`
      break  
    case 'clinician':
      supportEmail = `help${supportEmail}`  
      break
    default:
      supportEmail = `clients${supportEmail}`
  }
 
  return <span>Need help? Contact our support team: <a href={`mailto:${supportEmail}`}>{supportEmail}</a></span>
}