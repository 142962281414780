import React from 'react'

const Hourglass = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="32" r="32" fill="#2D54E8" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 26.6666V18.6666H40V26.6666H39.9867L40 26.68L34.6667 32L40 37.3333L39.9867 37.3466H40V45.3333H24V37.3466H24.0133L24 37.3333L29.3333 32L24 26.68L24.0133 26.6666H24ZM37.3333 39.3033V38L32 32.6666L26.6667 38V39.3033C28.2222 38.8229 32.5333 38.1502 37.3333 39.3033ZM26.6667 26L32 31.3333L37.3333 26V21.3333H26.6667V26Z" fill="white" />
  </svg>
)

export default Hourglass
