import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { includes, filter, xor, cloneDeep } from 'lodash'
import styles from './enroll.module.css'
import { isMobileOnly } from 'react-device-detect'
import {
  EnrollAssessment,
  Final,
  SafetyNet,
  Consent
} from '../../handlers/enroll'
import { PrimaryButton, Loading } from '../../components'
import queryString from 'query-string'
import { merge } from 'lodash'
import { Hourglass } from '../../components/icons'
import { Flex, Text } from '@chakra-ui/react'

class EnrollAssessmentCarousel extends Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
    this.assessmentErrorRef = React.createRef()

    this.state = {
      patientId: this.props.match.params['patient_id'],
      assessments: [],
      assessmentResponses: {},
      patientFirstName: '',
      clinicianDisplayName: '',
      errors: {},
      errorsForFinalScreen: {},
      isAssessmentsSubmitted: false,
      hasOverdue: true,
      allExpired: false,
      loading: true,
      submitting: false,
      sms_sendable: false,
      safetyNetPrompt: null,
      skippedSafetyNet: false,
      tookAsmtAnyway: false,
      shouldBlockNavigation: false,
      firstSkippedQuestionId: null,
      showConsent: false,
      consentCopy: null,
      clientPreferences: {}
    }
  }

  scrollToTop = behavior => {
    this.containerRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior
    })
  }

  componentDidMount = async () => {
    const { pathname, search } = this.props.location
    const { performance } = window
    const { assigneeUserId } = queryString.parse(this.props.location.search)
    if (
      performance &&
      performance.navigation.type === performance.navigation.TYPE_RELOAD
    ) {
      window.location = `${pathname.slice(0, pathname.length - 1)}0${search}`
    }
    // Ignore consent check if it isn't the client
    if (!assigneeUserId) {
      // check if consent is required and missing first
      const consentStatus = await this.fetchClientConsentStatus()
      if (consentStatus.required && !consentStatus.accepted) {
        this.setState({
          showConsent: true,
          consentCopy: consentStatus.copy
        })
      }      
    }

    if (!this.state.assessments.length) {
      this.fetchData()
    }
  }

  componentDidUpdate = () => {
    if (this.state.shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  componentWillUnmount = () => {
    if (this.timeoutId) clearTimeout(this.timeoutId)
  }

  // update error handling
  fetchClientConsentStatus = async () => {
    const { patientId } = this.state
    const { api, accessToken } = this.props
    let clinicId
    let consentStatus = {
      required: false
    }

    // first check if the client has given consent if neccessary
    // this happens first so we can get the clinic id if its missing
    const clientHasGivenConsentCheckPath = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/clinic/preferences`
    try {
      const response = await api.GET(
        accessToken,
        clientHasGivenConsentCheckPath
      )
      if (response.preferences)
        this.setState({
          clientPreferences: response.preferences
        })
      clinicId = response.clinicId
      consentStatus.accepted =
        (response &&
          response.preferences &&
          response.preferences.client_consent &&
          response.preferences.client_consent.has_accepted) ||
        false
    } catch (err) {
      this.setState({
        errors: {
          ...this.state.errors,
          consentCheck:
            'Oops, something went wrong. Please refresh the page and try again.'
        }
      })
    }

    // then check if the clinic/org requires consent
    const requirementCheckPath = `${process.env.REACT_APP_NODE_API_ROOT_URL}/v1/clinics/${clinicId}/preferences`
    try {
      const response = await api.GET(accessToken, requirementCheckPath)
      if (
        response &&
        response.preferences &&
        response.preferences.client_consent &&
        response.preferences.client_consent.should_show
      ) {
        consentStatus.required = true
        consentStatus.copy = response.preferences.client_consent.copy
      }
    } catch (err) {
      this.setState({
        errors: {
          ...this.state.errors,
          consentCheck:
            'Oops, something went wrong. Please refresh the page and try again.'
        }
      })
    }

    return consentStatus
  }

  // error handling
  giveConsent = async () => {
    const { patientId, clientPreferences } = this.state
    const { api, accessToken } = this.props

    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/clinic/preferences`
    const preferences = merge(clientPreferences, {
      client_consent: { has_accepted: true }
    })
    const body = JSON.stringify({ preferences })

    try {
      const response = await api.PUT(accessToken, path, body)
      if (response.preferences.client_consent.has_accepted)
        this.setState({ showConsent: false })
    } catch (err) {
      this.setState({
        errors: {
          ...this.state.errors,
          consentAccept:
            'Oops, something went wrong. Please refresh the page and try again.'
        }
      })
    }
  }

  fetchData = async () => {
    const { patientId } = this.state
    const { api, accessToken, location } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/assessments?patient_id=${patientId}&includeFreeTextQuestions=true`

    const { assigneeUserId } = queryString.parse(location.search)

    try {
      const response = await api.GET(accessToken, path)
      if (response.assessments) {
        let assessmentsToShow = response.assessments
        const { source: assessment_source } = queryString.parse(location.search)
        let { hasOverdue } = this.state

        const isManual = assessment_source.includes('manual') // e.g. if sent manually by clinician via "take now" feature
        if (!isManual) {
          // only screen out non-overdue assessments if not sent to page manually
          if (response.overdue_assessment_ids.length) {
            // if there is 1+ overdue assessment(s), only show those
            assessmentsToShow = response.assessments.filter(assessment =>
              response.overdue_assessment_ids.includes(assessment.id)
            )
          } else {
            hasOverdue = false
          }
        }

        if (assigneeUserId) {
          assessmentsToShow = assessmentsToShow.filter((a) => a.assigneeUser.id === assigneeUserId)
        }


        const assessmentResponses = await this.buildInitialResponses(
          assessmentsToShow
        )
        
        this.setState({
          assessments: assessmentsToShow,
          clinicianDisplayName: response.clinician.display_name,
          patientFirstName: response.patient.first_name,
          assessmentResponses,
          hasOverdue,
          allExpired: response.all_assessments_expired,
          loading: false,
          submitting: false
        })
      } else if (response.error) {
        this.setState({
          errors: {
            ...this.state.errors,
            connection: 'Unable to load assessment.'
          }
        })
      }
    } catch (e) {
      this.setState({
        errors: {
          ...this.state.errors,
          connection:
            'Oops, something went wrong. Please refresh the page and try again.'
        }
      })
    }
  }

  buildInitialResponses = assessments => {
    const assessmentResponses = {}
    assessments.forEach(assessment => {
      assessmentResponses[assessment.id] = {}
    })

    return assessmentResponses
  }

  validateAssessmentsAreComplete = assessmentAnswers => {
    return !assessmentAnswers.some(a => !a.answers.length)
  }

  handleIncompleteAssessments = () => {
    const { pathname, search } = this.props.location
    alert(
      'We apologize, there was an error saving your assessment and your responses were lost. Please complete your assessment again.'
    )
    this.setState({ shouldBlockNavigation: false })
    if (this.state.assessments.length > 1) {
      window.location = `${pathname.slice(0, pathname.length - 1)}0${search}`
    }
  }

  submitAssessments = () => {
    const { patientId } = this.state
    const { api, accessToken, user_role, location } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/assessment-scores`
    const { source: assessment_source, assigneeUserId } = queryString.parse(location.search)
    const assessments = this.formatAssessmentResponses()

    const allAssessmentsComplete = this.validateAssessmentsAreComplete(
      assessments
    )
    if (!allAssessmentsComplete) return this.handleIncompleteAssessments()

    const body = JSON.stringify({
      assessments: assessments,
      assigneeUserId,
      assessment_source,
      is_new_patient:
        this.props.location.state && this.props.location.state.isNewPatient
    })

    this.setState({ submitting: true })

    return api
      .POST(accessToken, path, body, user_role)
      .then(response => {
        this.setState({
          isAssessmentsSubmitted: true,
          submitting: false,
          smsSendable: response.sms_sendable,
          hasEmail: response.has_email,
          hasPhone: response.has_phone,
          safetyNetPrompt: response.prompt
        })

        if (this.state.assessments.length > 1) {
          // navigate to the first assessment so that going back in the browser doesn't go to the current patients assessments
          this.props.history.go(-(this.state.assessments.length - 1))
        }
      })
      .catch(() => {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              connection:
                'Oops, something went wrong. Please refresh the page and try again.'
            }
          },
          () => this.scrollToTop('auto')
        )
      })
  }

  formatAssessmentResponses = () => {
    const { assessmentResponses } = this.state
    let assessments = []
    let assessmentKeys = Object.keys(assessmentResponses)
    for (let assessmentKey in assessmentKeys) {
      let assessmentID = assessmentKeys[assessmentKey]
      let responses = assessmentResponses[assessmentID]
      let responseKeys = Object.keys(responses)
      let answers = []
      for (let responseKey in responseKeys) {
        let responseID = responseKeys[responseKey]
        answers.push({
          key: responseID,
          value: responses[responseID]
        })
      }
      assessments.push({
        id: assessmentID,
        answers: answers
      })
    }
    return assessments
  }

  fetchAnswerRefObjectByValue = (assessmentId, questionKey, answerValue) => {
    const assessmentRefObject = this.state.assessments.find(
      a => a.id === assessmentId
    )
    const questionRefObject = assessmentRefObject.content.sections[0].questions.find(
      q => q.key === questionKey
    )
    const answers =
      questionRefObject.answers ||
      assessmentRefObject.content.sections[0].answers
    return answers.find(a => a.value === answerValue)
  }

  handleAssessmentStateChange = (
    assessmentID,
    questionKey,
    value,
    isMultiSelect,
    isFreeText = false
  ) => {
    if (!isFreeText) {
      let newAssessmentResponses = { ...this.state.assessmentResponses }
      const current = newAssessmentResponses[assessmentID][questionKey]
      const valueAsNumber = parseFloat(value)

      const hasQuestionBeenAnswered = !!current
      const wasPrevAnswerAnOverride = hasQuestionBeenAnswered
        ? Array.isArray(current) && current.length >= 1
        ? isMultiSelect
        ? !!this.fetchAnswerRefObjectByValue(
          assessmentID,
          questionKey,
          current[0]
        ).isOverride
        : !!this.fetchAnswerRefObjectByValue(
          assessmentID,
          questionKey,
          current
        ).isOverride
        : false
        : false
      const isNewAnswerAnOverride = !!this.fetchAnswerRefObjectByValue(
        assessmentID,
        questionKey,
        valueAsNumber
      ).isOverride

      let valueToInsert
      if (
        isMultiSelect &&
        (!hasQuestionBeenAnswered ||
          wasPrevAnswerAnOverride ||
          isNewAnswerAnOverride)
      )
        valueToInsert = [valueAsNumber]
      else if (isMultiSelect)
        valueToInsert = this.determineMultiSelectValue(current, valueAsNumber)
      else valueToInsert = valueAsNumber

      newAssessmentResponses = {
        ...this.state.assessmentResponses[assessmentID],
        [questionKey]: valueToInsert
      }
      if (
        Array.isArray(newAssessmentResponses[questionKey]) &&
        !newAssessmentResponses[questionKey].length
      )
        delete newAssessmentResponses[questionKey]

      this.setState({
        assessmentResponses: {
          ...this.state.assessmentResponses,
          [assessmentID]: newAssessmentResponses
        },
        errors: {},
        shouldBlockNavigation: true
      })
    } else {
      this.setState(prevState => ({
        ...prevState,
        assessmentResponses: {
          ...prevState.assessmentResponses,
          [assessmentID]: {
            ...prevState.assessmentResponses[assessmentID],
            [questionKey]: value
          }
        }
      }))
    }
  }

  determineMultiSelectValue = (current, value) => {
    let cleansedCurrent = Array.isArray(current) ? current : []
    return xor(cleansedCurrent, [value])
  }

  handleChangeAssessment = async (direction, currentAssessmentIndex) => {
    const { match, history, location } = this.props
    let newIndex
    const { assessments } = this.state
    if (direction === 'forward') {
      const isValid = await this.isValidAssessmentResponses(
        currentAssessmentIndex
      )
      if (isValid) {
        if (currentAssessmentIndex === assessments.length - 1) {
          return this.submitAssessments()
        } else {
          newIndex = currentAssessmentIndex + 1
          history.push(
            `${match.url}/${newIndex}${location.search}`,
            location.state
          )
        }
      } else {
        this.scrollToTop('smooth')
        this.setState(
          {
            errors: {
              ...this.state.errors,
              assessment: '*Please answer every question.'
            }
          },
          () => {
            // Focus on error message for screen readers
            this.assessmentErrorRef.current.focus()
          }
        )
      }
    } else {
      history.goBack()
    }

    this.scrollToTop('auto')
  }

  isValidAssessmentResponses = currentAssessmentIndex => {
    const currentAssessment = cloneDeep(
      this.state.assessments[currentAssessmentIndex]
    )
    const responses = cloneDeep(
      this.state.assessmentResponses[currentAssessment.id]
    )
    const answeredQuestionKeys = Object.keys(responses)
    const allQuestions = currentAssessment.content.sections.flatMap(
      section => section.questions
    )

    // check if the assessment is missing any responses
    let isValid = true
    if (answeredQuestionKeys.length === allQuestions.length) return isValid

    const skippedQuestions = filter(
      allQuestions,
      question => !includes(answeredQuestionKeys, question.key)
    )
    for (const q of skippedQuestions) {
      if (q.skippable) responses[q.key] = null
      else {
        isValid = false
        this.setState({ firstSkippedQuestionId: q.key })
        break
      }
    }
    const assessmentResponses = cloneDeep(this.state.assessmentResponses)
    assessmentResponses[currentAssessment.id] = responses
    this.setState({ assessmentResponses: assessmentResponses })
    return isValid
  }

  isPatientSoftLoggedIn = () => {
    const { location } = this.props
    return location.state && location.state.origin
  }

  navigateToHomeScreen = () => {
    const { location, history } = this.props
    history.replace(location.state.origin)
  }

  navigateToFinalFromSafety = () => {
    this.setState({ skippedSafetyNet: true })
  }

  handleTakeAssessmentsAnyway = () => {
    if (this.timeoutId) clearTimeout(this.timeoutId)
    this.setState({ hasOverdue: true, tookAsmtAnyway: true })
  }

  renderContent = () => {
    if (this.state.loading) {
      return <Loading />
    }

    if (this.state.errors.consentCheck) {
      return (
        <span className={styles.consentError}>
          {this.state.errors.consentCheck}
        </span>
      )
    }

    const { patientFirstName, errors } = this.state
    const { location, match } = this.props
    return (
      <div className={styles.page}>
        {this.state.submitting && (
          <Loading className={styles.submitting_spinner} />
        )}

        <div ref={this.containerRef} className={styles.container}>
          <div className={styles.container_inner}>
            <div className={styles.title}>
              <h1>{`${patientFirstName}'s Assessment`}</h1>
            </div>
            {errors.connection && (
              <p className={styles.connection_error}>{errors.connection}</p>
            )}
            <p
              className={styles.intro_text}
            >Please complete all the questions below.</p>

            <Switch>
              {/* using a route here so the user can use the browser navigation to go back and forth between assessments */}
              <Route
                path={`${match.path}/:assessmentIndex`}
                render={this.renderAssessmentRoute}
              ></Route>
              {/* if no index is supplied, default to the first one */}
              <Route exact path={`${match.path}`}>
                <Redirect to={`${location.pathname}/0${location.search}`} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    )
  }

  renderAssessmentRoute = routeProps => {
    const { assessments, assessmentResponses, errors } = this.state
    const currentAssessmentIndex = parseInt(
      routeProps.match.params['assessmentIndex']
    )

    const currentAssessment = assessments[currentAssessmentIndex] || {}

    return (
      <>
        <div className={styles.assessment} key={currentAssessment.id}>
          <div className={styles.assessment_number_title}>
            <p className={styles.the_basics}>{currentAssessment.name}</p>
          </div>
          {errors.assessment && (
            <>
              <p
                role="alert"
                ref={this.assessmentErrorRef}
                className={styles.error_margin_bottom}
              >
                {errors.assessment}
                <a
                  href={`#${this.state.firstSkippedQuestionId}`}
                  className={styles.error_link}
                >
                  Take me to the first unanswered question that is not
                  skippable.
                </a>
              </p>
            </>
          )}

          <EnrollAssessment
            handleStateChange={this.handleAssessmentStateChange}
            assessment={currentAssessment}
            assessmentResponses={assessmentResponses}
            handleNavToFirstAssessment={this.handleNavToFirstAssessment}
          />

          {errors.blankAssessment && (
            <p className={styles.error_margin_bottom}>
              {errors.blankAssessment}
            </p>
          )}

          {/* render footer here since navigation depends on assessment index */}
          <div
            className={
              isMobileOnly
                ? styles.assessment_footer_mobile
                : styles.assessment_footer
            }
          >
            <div className={styles.footer_button_container}>
              {currentAssessmentIndex > 0 ? (
                <p
                  className={styles.back}
                  tabIndex="0"
                  role="button"
                  onClick={() =>
                    this.handleChangeAssessment('back', currentAssessmentIndex)
                  }
                  onKeyDown={e => {
                    const key = e.which
                    if (key === 13 || key === 32)
                      this.handleChangeAssessment(
                        'back',
                        currentAssessmentIndex
                      )
                  }}
                >
                  {' '}
                  <span aria-hidden={'true'}>←</span> Back{' '}
                </p>
              ) : (
                <div />
              )}
              <PrimaryButton
                className={
                  isMobileOnly
                    ? currentAssessmentIndex > 0
                      ? styles.second_assess_action
                      : styles.mobile_action
                    : styles.action
                }
                round={true}
                onClick={() =>
                  this.handleChangeAssessment('forward', currentAssessmentIndex)
                }
              >
                Next
              </PrimaryButton>
            </div>
          </div>
        </div>
      </>
    )
  }

  notifyClinicianOfArrival = async () => {
    const { accessToken, user_role, api } = this.props
    const { patientId } = this.state
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/${patientId}/clinicians/arrival-notifications`

    const response = await api.POST(accessToken, path, null, user_role)
    if (response.error) {
      this.setState({ errorsForFinalScreen: { notification: response.error } })
    }
  }

  renderFinalOrSafety = () => {
    const { location } = this.props
    const { skippedSafetyNet, tookAsmtAnyway } = this.state
    if (
      location.state &&
      location.state.notifyClinician &&
      !skippedSafetyNet &&
      !tookAsmtAnyway
    )
      this.notifyClinicianOfArrival() // so the clinician won't be notified twice if the patient skips the safety net or clicks take anyway
    if (this.state.skippedSafetyNet) return this.renderFinalPage()
    if (this.state.safetyNetPrompt) return this.renderSafetyNet()
    return this.renderFinalPage()
  }

  renderSafetyNet = () => {
    return (
      <SafetyNet
        prompt={this.state.safetyNetPrompt}
        navigateToFinalFromSafety={
          this.isPatientSoftLoggedIn() ? this.navigateToFinalFromSafety : null
        }
        errors={this.state.errorsForFinalScreen}
        navigateToHomeScreen={this.navigateToHomeScreen}
      />
    )
  }

  renderFinalPage = () => {
    const { location, api } = this.props
    const {
      hasEmail,
      hasPhone,
      hasOverdue,
      smsSendable,
      clinicianDisplayName,
      patientId,
      assessments
    } = this.state
    return (
      <Final
        actionTaken={location.state && location.state.action_taken}
        api={api}
        clinicianDisplayName={clinicianDisplayName}
        errors={this.state.errorsForFinalScreen}
        handleTakeAssessmentsAnyway={this.handleTakeAssessmentsAnyway}
        hasAssessments={assessments.length > 0}
        hasEmail={hasEmail}
        hasOverdue={hasOverdue}
        hasPhone={hasPhone}
        navigateToHomeScreen={
          this.isPatientSoftLoggedIn() ? this.navigateToHomeScreen : null
        }
        patientId={patientId}
        smsSendable={smsSendable}
        source={queryString.parse(location.search).source.toUpperCase()}
      />
    )
  }

  renderConsent = () => {
    const { location } = this.props
    return (
      <Consent
        copy={this.state.consentCopy}
        giveConsent={this.giveConsent}
        navigateToHomeScreen={this.navigateToHomeScreen}
        error={this.state.errors.consentAccept}
        source={queryString.parse(location.search).source}
      />
    )
  }

  renderAllExpired = () => (
    <Flex align="center" direction="column" mt="large">
      <Hourglass />
      <Text
        fontSize="x-large"
        fontWeight="bold"
        mt="medium"
      >This link is no longer available</Text>
    </Flex>
  )

  render() {
    if (this.state.allExpired) {
      return this.renderAllExpired()
    } else if (this.state.isAssessmentsSubmitted || this.state.hasOverdue === false) {
      return this.renderFinalOrSafety()
    } else if (this.state.showConsent) {
      return this.renderConsent()
    }
    return this.renderContent()
  }
}

export default withRouter(EnrollAssessmentCarousel)
