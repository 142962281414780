import React from 'react'
import Paper from '@material-ui/core/Paper';
import styles from './unsubscribesuccessful.module.css'
import { CircleCheck } from '../components/icons'

export default function UnsubscribeSuccessful() {
  return (
    <div className={styles.page}>
      <Paper elevation={0} className={styles.container}>
        <div className={styles.successDiv}>
          <div className={styles.successCheckContainer}>
            <CircleCheck className={styles.successCheck} fill="#44CD7D" />
          </div>
          <h1 className={styles.header}>You have been successfully unsubscribed.</h1>
        </div>
      </Paper>
    </div>
  )
}

