import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Check } from '../icons'
import Button from '@material-ui/core/Button';
import styles from './primarybutton.module.css'
import cx from 'classnames'

export default class PrimaryButton extends Component {
  render () {
    return (
      <Button
        fullWidth
        variant='contained'
        color='inherit'
        onClick={this.props.onClick}
        className={cx(styles.button, this.props.className, this.props.fullWidth && styles.fullWidth, this.props.success && styles.success)}
        disabled={this.props.disabled || this.props.loading ? true : false}
        type={this.props.type ? this.props.type : 'button'}
      >
          {this.props.loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : this.props.success ? (
            <Check fill="#ffffff" />
          ) : (
                this.props.children
          )}
      </Button>
    )
  }
}
