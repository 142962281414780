import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import { Loading } from '../../components'
import { CircleQuestion } from '../../components/icons'
import { withStyles, TextField } from '@material-ui/core'
import cx from 'classnames'
import _ from 'lodash'

const styles = theme => {
  let styles = {
    container: {
      padding: '31px 24px 0',
      marginTop: '30px',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontFamily: theme.fontFamily,

      '& h3': {
        textTransform: 'uppercase',
        color: '#757575',
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between '
      },

      '& p': {
        lineHeight: '1.4'
      }
    },
    title: {
      marginBottom: '1rem',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 28,
    },
    daysContainer: {
      marginTop: '3rem'
    },
    daysOfTheWeek: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    day: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #ccc',
      borderRadius: 3,
      minWidth: 39,
      minHeight: 39,
      textAlign: 'center',
      cursor: 'pointer',

      '&.selected': {
        backgroundColor: theme.selectedDayColor,
        color: 'white',
        border: '1px solid transparent'
      },
      '&.excluded': {
        backgroundColor: 'white',
        color: '#CCCCCC',
        border: '1px solid #E6E6E3'
      },

      '&.everyDay': {
        minHeight: 45,
        marginTop: 5,
      },
    },
    timeContainer: {
      marginTop: '2rem',
      flexGrow: 1
    },
    timepickerContainer: {
      display: 'flex',
      justifyContent: 'center'
    },
    timePicker: {
      width: '161px',

      '& input': {
        textAlign: 'center'
      }
    },
    turnOffText: {
      alignSelf: 'center',
      marginBottom: '3rem',

      '&.disabled': {
        color: '#cccccc'
      }
    },
    redExplanation: {
      color: '#E60000'
    },
    errorMessage: {
      color: '#e60000',
      fontSize: '0.9rem',
      textAlign: 'center',
      marginBottom: '0.5rem',
    }
  }

  if (theme.styles && theme.styles.notificationPreferences) {
    styles = _.merge(styles, theme.styles.notificationPreferences)
  }

  return styles
}

class NotificationPreferences extends Component {
  constructor(props) {
    super(props)

    const queryParams = new URLSearchParams(this.props.location.search)
    const notificationType = queryParams.get('notification_type') || 'assessment'

    this.state = {
      loading: true,
      notificationType,
      patientId: this.props.match.params['patient_id'],
      selectedDays: [],
      excludedDays: [],
      selectedTime: moment().format('HH:mm'),
      assessmentCadence: 'weekly',
      clinicianDisplayName: 'your clinician',
      error: null,
      isTimeLocked: false,
      hasClinicianOverride: false
    }
  }

  componentDidMount = () => {
    window.app = this
    this.fetchData()
  }

  confirmNoDays = () => {
    const { patientId, notificationType } = this.state
    const { api, accessToken } = this.props

    const body = JSON.stringify({
      patient_id: patientId,
      notification_type: notificationType
    })

    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/all_patient_notification_preferences`
    return api.DELETE(accessToken, path, body).then(response => {
      if (response.error) {
        this.setState({
          error: 'Oops, something went wrong. Please try again.'
        })

        return
      }

      this.postMessageToMobile('handlePreferencesSaved', null, 'saveSuccess')
    })
    .catch(() => {
      this.setState({
        error: 'Oops, something went wrong. Please try again.'
      })
    })
  }

  turnOffAssessmentReminders = () => {
      this.postMessageToMobile('handleTurnOffAssessmentReminders', null, 'assessmentRemindersTurnedOff')
  }

  savePreferences = () => {
    const {
      patientId,
      selectedDays,
      selectedTime,
      notificationType
    } = this.state
    const { api, accessToken } = this.props

    let selectedDaysToSubmit = selectedDays
    if (selectedDaysToSubmit.includes(7)) {
      selectedDaysToSubmit = [0, 1, 2, 3, 4, 5, 6]
    }

    const body = JSON.stringify({
      patient_id: patientId,
      days_of_week: selectedDaysToSubmit,
      time_of_day: moment(selectedTime, 'HH:mm').format(), // ensures the time is sent over in the current user's timezone
      notification_type: notificationType
    })

    if (selectedDaysToSubmit.length === 0) {
      this.postMessageToMobile('handlePreferencesSaved', 'no_days_selected', 'noDaysSelected')
    } else {
      const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/notification-preferences`
      return api.POST(accessToken, path, body).then(response => {
        if (response.error) {
          this.setState({
            error: 'Oops, something went wrong. Please try again.'
          })

          return
        }

        this.postMessageToMobile('handlePreferencesSaved', 'success', 'saveSuccess')
      })
      .catch(() => {
        this.setState({
          error: 'Oops, something went wrong. Please try again.'
        })
      })
    }
  }

  fetchData = () => {
    const { patientId, notificationType } = this.state
    const { api, accessToken } = this.props
    const path = `${process.env.REACT_APP_NODE_API_ROOT_URL}/patients/notification-preferences?patient_id=${patientId}&notification_type=${notificationType}`

    return api.GET(accessToken, path).then(response => {
      if (response.error) {
        this.setState({ error: response.error, loading: false })
        return
      }

      let { selectedDays, selectedTime } = this.state
      const clinicianDisplayName =
        response.clinician_display_name || this.state.clinicianDisplayName

      const assessmentCadence =
        this.getAssessmentCadence(
          response.cadence_value,
          response.cadence_unit
        ) || this.state.assessmentCadence
        
      const days_excluded = response.days_excluded

      if (response.patient_notification_preferences.length) {
        if (notificationType === 'assessment') {

          if (response.override_date_time) {
            selectedTime = moment(response.override_date_time).format('HH:mm')
            this.setState({ isTimeLocked: true, hasClinicianOverride: true}) // do these need to be decoupled?

          } else {
            const [preference] = response.patient_notification_preferences
            const { days_of_week, time_of_day } = preference
            const selectedDaysLocal = days_of_week.map(d => {
              // get the UTC day of the returned value and convert it to local
              return moment(moment.utc(time_of_day, 'HH:mm:ssZ').day(d).format()).day()
            })
  
            selectedDays = selectedDaysLocal
            selectedTime = moment(time_of_day, 'HH:mm:ssZ').format('HH:mm')
          }

        }

      // auto select the only available day if all others are excluded
      if (days_excluded.length > 1) {
        selectedDays = [0, 1, 2, 3, 4, 5, 6].filter(day => !days_excluded.includes(day))
      }

        // TODO CC 02-12-2020 - parse existing check-in reminders? Usually just creating new ones on this screen
      }
      this.setState({
        selectedDays,
        selectedTime,
        clinicianDisplayName,
        assessmentCadence,
        excludedDays: days_excluded,
        loading: false
      }, () => {
        this.postMessageToMobile('handleLoaded', 'success', 'loadSuccess')
      })
    })
  }

  postMessageToMobile = (message, body, rn_body) => {
    // post message to iOS app
    if (window.webkit) {
      window.webkit.messageHandlers[message].postMessage(body)
    } 
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(rn_body)
    }
  }

  getAssessmentCadence = (cadence_value, cadence_unit) => {
    if (!cadence_value || !cadence_unit) return null
    // TODO CC 01-13-2020 - find a library to do this? otherwise, we'll have to change this anytime we edit possible cadence values
    if (cadence_value === 1) {
      if (cadence_unit === 'weeks') {
        return 'weekly'
      } else if (cadence_unit === 'months') {
        return 'monthly'
      }
    } else if (cadence_value === 2) {
      if (cadence_unit === 'weeks') {
        return 'bi-weekly'
      }
    }
  }

  handleDaySelect = index => {
    const { selectedDays, notificationType, excludedDays } = this.state
    const i = selectedDays.indexOf(index)

    // do nothing if this day is excluded
    if (notificationType === 'assessment' && excludedDays.includes(index)) return

    // only one day can be set for an assessment
    if (notificationType === 'assessment') {
      if (i >= 0) {
        this.setState({ selectedDays: [] })
      } else {
        this.setState({ selectedDays: [index] })
      }

      return
    }

    // reset "Every day" if it's selected
    if (selectedDays.includes(7)) {
      selectedDays.splice(0, 1)
    }

    // remove the day if it's already selected, otherwise add it
    if (i >= 0) {
      selectedDays.splice(i, 1)
    } else {
      selectedDays.push(index)
    }

    this.setState({ selectedDays })
  }

  handleEveryDaySelect = () => {
    if (this.state.selectedDays.includes(7)) {
      this.setState({ selectedDays: [] })
    } else {
      this.setState({ selectedDays: [7] })
    }
  }

  handleTimeSelect = e => {
    this.setState({ selectedTime: e.target.value })
  }

  renderIntroText = (classes) => {
    const { notificationType, excludedDays, assessmentCadence, hasClinicianOverride } = this.state
    if (notificationType === 'check_in') return
    if (hasClinicianOverride) {
      return (
        <p className={classes.redExplanation}>
         Your clinician has selected a day and time for your assessments based on your appointment schedule and treatment plan. 
         If you&apos;d like to adjust the day/time of your assessments, please contact your clinician.
        </p>
      )
    }
    if (excludedDays.length === 1 || !excludedDays.length) {
      return (
        <p>
          Pick a day and time to complete your {assessmentCadence}{' '}
          assessment. Your clinician may adjust this in the future based on your appointment schedule
          and treatment plan.
        </p>
      )
    }
    if (excludedDays.length > 1) {
      return (
        <p>
          Your clinician has selected a day of the week based on your appointment schedule and treatment
          plan. Select a time that is convinient for you on this day.
        </p>
      )
    }   
  }

  renderTurnOffAssessmentReminders = (classes) => {
    const { notificationType, hasClinicianOverride } = this.state
    if (notificationType === 'assessment') {
      if (hasClinicianOverride) {
        return <p className={cx(classes.turnOffText, 'disabled')} onClick={() => {}}>Turn off Clinical Assessment Reminders</p>
      }
    
      return <p 
        className={classes.turnOffText} 
        onClick={this.turnOffAssessmentReminders}
        tabIndex="0"
        role='button'
        onKeyDown={e => {
          const key = e.which
          if (key === 13 || key === 32) this.turnOffAssessmentReminders()
        }}
        >Turn off Clinical Assessment Reminders</p>
    }
  }
  
  render() {
    if (this.state.loading) {
      // rely on mobile loading icon, otherwise show our own
      if (window.webkit) return null
      return <Loading />
    } 

    const {
      selectedDays,
      selectedTime,
      notificationType,
      error,
      excludedDays,
      isTimeLocked
    } = this.state

    const { classes } = this.props

    return (
      <div className={classes.container}>
        <h1 className={classes.title}>
          {notificationType === 'assessment' &&
            'Set a reminder for your clinical assessment'}
          {notificationType === 'check_in' && <>Add a check-in reminder</>}
        </h1>

        {this.renderIntroText(classes)}

        <div className={classes.daysContainer}>
          {error && <p className={classes.errorMessage}>{error}</p>}
          <h3>
            Remind me on {notificationType === 'assessment' ? 'this day' : 'these days'}
            {!!excludedDays.length && <div onClick={() => this.postMessageToMobile('handleInfoPress', 'open', 'openInfo')}>
              <CircleQuestion />
            </div>}
          </h3>
          <div className={classes.daysOfTheWeek}>
            {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
              <div
                className={cx(classes.day, {
                  'selected': selectedDays.includes(index),
                  'excluded': notificationType === 'assessment' && excludedDays.includes(index)
                })}
                key={index}
                aria-label={day}
                onClick={() => this.handleDaySelect(index)}
                tabIndex="0"
                role='button'
                onKeyDown={e => {
                  const key = e.which
                  if (key === 13 || key === 32) this.handleDaySelect(index)
                }}
              >
                {day[0]}
              </div>
            ))}
          </div>
          {notificationType === 'check_in' &&
            <div
              className={cx(classes.day, 'everyDay', { 'selected': selectedDays.includes(7)})}
              onClick={this.handleEveryDaySelect}
            >
              Every day
            </div>
          }
        </div>

        <div className={classes.timeContainer}>
          <h3>Remind me at this time</h3>
          <div className={classes.timepickerContainer}>
            <TextField
              type="time"
              className={classes.timepicker}
              variant="outlined"
              onChange={this.handleTimeSelect}
              value={selectedTime}
              required
              disabled={isTimeLocked}
            />
          </div>
        </div>
        {this.renderTurnOffAssessmentReminders(classes)}
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(NotificationPreferences))
