import React, { Component } from 'react';
import { Logo } from '../icons'
import AppBar from '@material-ui/core/AppBar';
import styles from './navigation.module.css'

export default class Navigation extends Component {
  logout = () => {
    this.props.auth.logout();
  }

  render() {
    const isLoggedIn = this.props.accessToken
    return (
      <AppBar color='primary' position="fixed">
        <div className={styles.nav_inner}>
          <div className={styles.logo}>
            <Logo color="white"/>
          </div>
          {isLoggedIn &&
            <div className={styles.links}>
              {/* eslint-disable-next-line */}
              <a
                className={styles.link}
                style={{ cursor: 'pointer' }}
                onClick={this.logout}
              >
                Log Out
              </a>
            </div>
          }
        </div>
      </AppBar>
    )
  }
}
